import { getValue } from "@/cms-services/helpers";
import { Vue, Prop } from "vue-property-decorator";

export default class Provider extends Vue {
  @Prop() provider!: any;
  @Prop() model!: any;
  @Prop() actions!: any;
  @Prop() nested!: any;
  @Prop() customValidators!: any;
  @Prop() field!: any;
  @Prop() editMode!: boolean;

  get canShow() {
    const bindedFieldName = this.field?.bindedFieldName;
    const bindedFieldValue = this.field?.bindedFieldValue;
    if (!bindedFieldName) return true;

    return bindedFieldValue == getValue(this.model, bindedFieldName);
  }

  get value() {
    const regex = new RegExp(/[\.]/gm);
    if (!regex.test(this.field.name)) {
      return this.model[this.field.name];
    }
    let schema = this.model;

    const pList = this.field.name.split(".");
    const len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    return schema[pList[len - 1]];
  }

  set value(value: any) {
    const regex = new RegExp(/[\.]/gm);
    if (!regex.test(this.field.name)) {
      this.model[this.field.name].push(value);
    }
    let schema = this.model;

    const pList = this.field.name.split(".");
    const len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }
    schema[pList[len - 1]].push(value);
  }
}
