
import {
  mdiMagnify,
  mdiDotsVertical,
  mdiPlusCircleOutline,
  mdiPen,
  mdiTrashCan,
  mdiFolderOpen,
  mdiFolder,
  mdiAutorenew,
} from "@mdi/js";
import _ from "lodash";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import ObjectExpansionEditor from "@/components/helpers/object-expansion-editor.vue";
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import { IBaseConfig } from "@/ioc/types";
import { TreeDataSource } from "@/data/Tree/TreeDataSource";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import { getParentBrachesOfTreeByKeyList } from "@/cms-services/helpers";
import ResourceFolderRestrictor from "@/components/helpers/resource-folder-restrictor.vue";

const defaultOverrideInfo = [
  {
    name: "instanceStateId",
    mode: "switch",
    activeState: {
      id: 4,
      caption: "Активен",
    },
    disactiveState: {
      id: 1,
      caption: "Создан",
    },
  },
];

@Component({
  components: {
    DataEditorDialog,
    ObjectExpansionEditor,
    ObjectDataTable,
    ResourceFolderRestrictor,
  },
})
export default class DataList extends Vue {
  @Ref("modelCreator") modelCreator!: DataEditorDialog;
  @Ref("folderEditor") folderEditor!: DataEditorDialog;
  @Ref("restrictor") restrictor!: ResourceFolderRestrictor;
  @Prop({ default: null }) customStateColor!: Function;
  @Prop() customHeaders!: any[];
  @Prop() dataSource!: ListDataSource;
  @Prop() breadcrumbs!: any;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop({ default: null }) customCreateItemFields!: any;
  @Prop({ default: null }) customCreateItemModel!: any;
  @Prop({ default: 1200 }) searhcTimeout!: number;
  @Prop({}) propertiesOverrideInfo!: any;
  @Prop({}) propertyGroupsOverrideInfo!: any;
  @Prop({}) folderConfig!: any;
  @Prop({}) customMetaData!: any;
  @Prop({ default: true }) activatable!: boolean;
  @Prop({ default: () => ({}) }) createItemActions: any;
  @Prop({ default: () => ({}) }) createFolderActions: any;
  @Prop({ default: null }) componentContextMenuCaption!: string;
  @Prop({ default: "caption" }) componentContextMenuCaptionFieldName!: string;
  @Prop({ default: true }) showSelect!: boolean;

  currentFolderId: number | null = null;
  sellectedItems: any = [];
  selectedFolder: any = [];
  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    refresh: mdiAutorenew,
  };
  folderContextMenu: any = {
    visible: false,
    x: null,
    y: null,
    data: null,
  };
  navigation: any = {
    shown: true,
    width: 400,
    minSize: 100,
  };
  items: any = [];

  goToPage(id: string) {
    this.$router.push({
      name: this.dataSource.className + "-id",
      params: { id },
    });
  }

  loading: boolean = false;
  loaded: boolean = false;
  createItemFields: any = [];
  createItemModel: any = {};
  multipleState: number = 1;
  searchText: string = "";
  headers: any = [];
  $message: any;
  createFolderFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "systemname",
      attrs: {
        label: "Системное имя",
        type: "text",
      },
      name: "name",
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
      validations: [],
    },
  ];
  $confirm: any;
  resourceFolderDataSource: TreeDataSource = new TreeDataSource({
    className: "",
  });
  searchTimer: any;

  get objectId() {
    return this.folderContextMenu.data?.id;
  }

  get allowFolder(): boolean {
    return this.folderConfig?.className && this.folderConfig?.rootFolder;
  }

  get hasFolders() {
    return this.resourceFolderDataSource.items?.length > 0;
  }

  @Watch("searchText")
  debounceSearch(value: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      this.refreshData({
        filter: JSON.stringify([
          {
            fieldName: "searchText",
            fieldValue: value,
          },
        ]),
      });
    }, this.searhcTimeout);
  }

  async restrictFolder() {
    await this.restrictor.restrict(this.folderContextMenu.data);
  }

  async setEvents() {
    await this.$nextTick();
    const minSize = this.navigation.minSize;
    const leftPanel = this.$refs.leftPanel as HTMLElement;
    const border = this.$refs.border as HTMLElement;
    const resize = (e) => {
      document.body.style.cursor = "ew-resize";
      let leftPos = leftPanel?.parentElement?.getBoundingClientRect()?.left;
      let f = e.clientX - (leftPos ?? 0);
      leftPanel.style.flexBasis = (f > minSize ? f : minSize) + "px";
    };

    border?.addEventListener(
      "mousedown",
      (e: any) => {
        if (e.offsetX < minSize) {
          leftPanel.style.transition = "initial";
          document.addEventListener("mousemove", resize, false);
        }
      },
      false
    );

    document.addEventListener(
      "mouseup",
      () => {
        leftPanel.style.transition = "";
        this.navigation.width = leftPanel.style.flexBasis;
        document.body.style.cursor = "";
        document.removeEventListener("mousemove", resize, false);
      },
      false
    );
  }

  async openFolderContextMenu(data, e) {
    this.folderContextMenu.visible = false;
    this.folderContextMenu.x = e.clientX;
    this.folderContextMenu.y = e.clientY;
    await this.$nextTick();
    this.folderContextMenu.data = data;
    this.folderContextMenu.visible = true;
  }

  async addFolder() {
    const _data: any = await this.folderEditor.create({
      parentId: null,
      caption: null,
      description: null,
      name: null,
    });
    if (!_data) return;
    try {
      _data.parentId = this.folderContextMenu?.data?.id;
      await this.resourceFolderDataSource.add(_data);
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async updateFolder() {
    const _data: any = await this.folderEditor.update(
      this.folderContextMenu.data
    );
    if (!_data) return;
    try {
      await this.resourceFolderDataSource.update(_data);
      this.$message("Успешно Обновлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async removeFolder() {
    if (this.folderContextMenu.data?.children?.length > 0) {
      return this.$message(
        "Нельзя удалять элементы у которых есть дочерние элементы",
        "error"
      );
    }

    try {
      await this.resourceFolderDataSource.remove(
        this.folderContextMenu.data.id
      );
      this.$message("Успешно удалён");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async addRootFolder() {
    await this.resourceFolderDataSource.add({
      parentId: null,
      caption: "Все",
      name: "mainFolder",
    });
  }

  async created() {
    await this.dataSource.get();
    !this.customMetaData
      ? await this.dataSource.getMetaData()
      : (this.dataSource.metadata = this.customMetaData);

    if (this.allowFolder) {
      this.resourceFolderDataSource.className = this.folderConfig.className;
      await this.resourceFolderDataSource.get(this.folderConfig.rootFolder);
    }

    this.createItemFields = this.getCreateItemFields(
      this.dataSource?.metadata?.properties,
      this.customCreateItemFields
    );

    this.createItemModel = this.getCreateItemModel(
      this.createItemFields,
      this.customCreateItemModel
    );

    this.loaded = true;
    await this.$nextTick();
    if (this.allowFolder) {
      await this.setEvents();
    }
  }

  overrideProperties() {
    this.defaultOverride();
    if (!this.propertiesOverrideInfo) return;
    const properties = this.dataSource?.metadata?.properties;
    for (const property of properties) {
      for (const propertyOverrideInfo of this.propertiesOverrideInfo) {
        if (property.name != propertyOverrideInfo.name) continue;
        Object.assign(property, propertyOverrideInfo);
      }
    }
  }

  defaultOverride() {
    const properties = this.dataSource?.metadata?.properties;
    if (!(properties?.length > 0)) return;
    for (const property of properties) {
      for (const propertyOverrideInfo of defaultOverrideInfo) {
        if (property.name != propertyOverrideInfo.name) {
          Object.assign(property, {
            readonly: !["instanceStateId"].includes(property.name),
          });
        } else {
          Object.assign(property, {
            ...propertyOverrideInfo,
            readonly: !["instanceStateId"].includes(property.name),
          });
        }
      }
    }
  }

  overridePropertyGroups() {
    if (!this.propertyGroupsOverrideInfo) return;
    const groups = this.dataSource?.metadata?.groups;
    for (const group of groups) {
      for (const groupOverrideInfo of this.propertyGroupsOverrideInfo) {
        if (group.name != groupOverrideInfo.name) continue;
        Object.assign(group, groupOverrideInfo);
      }
    }
  }

  getCreateItemFields = (fields: any[], custom: any[]) => {
    if (custom) return [...custom];

    if (!(fields?.length > 0)) {
      throw new Error("метаданные не определены");
    }
    const hasRequired = (v: any) =>
      v.filter((i: any) => i.validator === "required")?.length > 0;

    return fields
      .filter((f: any) => hasRequired(f?.validations))
      .map((f: any) => this.getEditorConfig(f));
  };

  getCreateItemModel = (fields: any, custom: any) => {
    if (custom) return { ...custom };
    if (!(fields?.length > 0)) {
      throw new Error("метаданные не определены");
    }
    const object = {};
    for (const field of fields) object[field.name] = null;

    return object;
  };

  getEditorConfig(field: any) {
    const { name, caption, editor, validations, config } = field;
    let attrs = {};

    switch (editor) {
      default: {
        attrs = {
          type: "text",
          label: caption,
        };
        break;
      }
    }

    return {
      editor,
      name,
      config,
      attrs,
      validations,
    };
  }

  async removeCascade(item) {
    const confirm = await this.$confirm({
      title: "Вы уверены что хотите удалить всё?",
      text: "Восстановление удаленных данных невозможно",
    });
    if (!confirm) return;
    try {
      await this.dataSource.removeCascade([item]);
      this.$message("Успешно удалено");
    } catch (error: any) {
      this.$message(error?.response?.data, "error");
    }
  }

  async addItem() {
    this.createItemFields = this.getCreateItemFields(
      this.dataSource?.metadata?.properties,
      this.customCreateItemFields
    );

    this.createItemModel = this.getCreateItemModel(
      this.createItemFields,
      this.customCreateItemModel
    );

    const model = await this.modelCreator.update(this.createItemModel);
    if (!model) return;

    try {
      if (this.allowFolder && !this.currentFolderId) {
        return this.$message("Пожалуйста выберите категорию", "error");
      }
      const component = await this.dataSource.add(model);

      await this.updateFolders(component.id, this.currentFolderId);
    } catch (error) {
      this.$message(error, "error");
    }

    if (this.dataSource.items.length === 1) {
      await this.refreshData();
    }
  }

  async updateFolders(compnentId: number, folderId: number | null) {
    if (!this.allowFolder) return;
    if (!folderId) throw "Пожалуйста выберите категорию";
    const items = getParentBrachesOfTreeByKeyList(
      [folderId],
      this.resourceFolderDataSource.items
    );
    await this.resourceFolderDataSource.updateFolders(
      items.map((i: any) => i.id),
      compnentId
    );
  }

  async chooseFolder(sellectedFolders: number[]) {
    if (!(sellectedFolders?.length > 0)) return;
    this.currentFolderId = sellectedFolders[0];
    this.folderConfig.folderId = sellectedFolders[0];
    const filter = JSON.stringify([
      {
        fieldName: "folderId",
        fieldValue: sellectedFolders[0],
      },
    ]);
    await this.refreshData({ filter });
  }

  async refreshData(config: IBaseConfig = {}) {
    this.loading = true;
    Object.assign(this.dataSource.config, config);
    await this.dataSource.get();
    this.items = this.dataSource.items;
    this.loading = false;
  }

  async refreshAll() {
    this.loading = true;
    await Promise.all([
      this.dataSource.get(),
      this.resourceFolderDataSource.get(this.folderConfig.rootFolder),
    ]);

    this.items = this.dataSource.items;

    this.loading = false;
    this.$message("Данные успешно обновлены");
  }
}
