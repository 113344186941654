
import { Filter, FilterUtils } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class InineCitySelector extends Vue {
  loaded: boolean = false;
  filterUtils: FilterUtils = new FilterUtils([]);
  cityDataSource: ListDataSource = new ListDataSource({
    className: "region",
    config: {
      pageSize: 1200,
    },
  });
  dataSource!: ObjectDataSource;

  get countryId() {
    return this.dataSource?.model?.countryId;
  }

  value: any;

  get valueCaption() {
    const cb = (c: any) => +c.id === +this.value;
    const country = this.cityDataSource.items.find(cb);
    return country?.name;
  }

  @Watch("dataSource.model", { deep: true })
  async onCountryChanged(model: any) {
    const countryId = model?.countryId;
    if (!countryId) return;
    this.filterUtils.clear();
    this.filterUtils.add(new Filter("parentId", countryId));
    this.cityDataSource.config.filter = this.filterUtils.filterString;
    await this.cityDataSource.get();
  }

  async created() {
    await this.onCountryChanged(this.dataSource?.model);
    this.loaded = true;
  }
}
