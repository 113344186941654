import Vue from "vue";
import App from "./App.vue";
import { initRoutes } from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./components/common";
import "./bootstrap";
import "cropperjs/dist/cropper.css";
import "normalize.css";
const { VueMaskDirective } = require("v-mask");
import "moment/locale/ru";
import messageModule from "@/store/plugins/message";
import confirmModule from "@/store/plugins/confirm";
import axios from "axios";
import VueMeta from "vue-meta";
import { ObjectDataSource } from "./data/Object/ObjecDatatSource";
import _ from "lodash";
import AppForm from "@/components/app-form/app-form.vue";
import FileUploader from "@/components/file-uploader.vue";
import VueKonva from "vue-konva";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import appConfigModule from "./store/plugins/appConfig";
import ContentBlockCanvas from "@/bootstrap/components/content/block/canvas.vue";
import ContentBlockController from "@/bootstrap/components/content/block/controller.vue";
import ContentBlockImage01 from "@/bootstrap/components/content/block/img/01.vue";
import "vue-swatches/dist/vue-swatches.css";
import VuetifyDraggableTreeview from "vuetify-draggable-treeview";
import { VBtn } from "vuetify/lib";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";

//12232
Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: "mdi",
});

Vue.use(VuetifyDraggableTreeview);
Vue.use(CKEditor);
Vue.use(VueKonva);
Vue.component("app-form", AppForm);
Vue.component("file-uploader", FileUploader);
Vue.component("content-block-canvas", ContentBlockCanvas);
Vue.component("content-block-controller", ContentBlockController);
Vue.component("ContentBlockImage01", ContentBlockImage01);
Vue.component("VBtn", VBtn);

Vue.use(VueMeta);
Vue.directive("mask", VueMaskDirective);
Vue.mixin({
  data: () => ({
    vuex: store,
  }),
  methods: {
    $message: (message, type = "success", icon = "", timeout = 3000): void =>
      messageModule.showMessage({ message, type, icon, timeout }),
    $confirm: (confirm: any) => confirmModule.open(confirm),
    $getObjectDataSource: (
      className: string,
      id: number,
      model?: any,
    ): ObjectDataSource =>
      new ObjectDataSource({ config: {}, className, id, model }),
    $getEditorName: (item: any): string => {
      if (!item) throw new Error("Мета данные не пераданы");
      const { editor } = item;
      return `inline-editor-${editor}`;
    },
  },
});

Vue.config.productionTip = false;

declare global {
  interface Array<T> {
    toFilterString: Function;
  }
  interface File {
    toBase64: Function;
  }
}
File.prototype.toBase64 = function () {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(this);
    reader.onload = () => {
      if (reader.result) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      }
    };
    reader.onerror = reject;
  });
};
Array.prototype.toFilterString = function () {
  return JSON.stringify(this);
};

axios({
  url: `/api/v1/config`,
  baseURL: process.env.VUE_APP_CONFIG,
  method: "GET",
}).then(({ data }) => {
  axios.defaults.baseURL = data.apiPrefix;
  const router = initRoutes(data);
  appConfigModule.setConfig(data);
  Vue.config.devtools = true;
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
