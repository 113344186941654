import Vue from "vue";
import store from "@/store";
import contentBlock, {
  contentBlockModule,
} from "@/bootstrap/store/contentBlock";
import {
  ModalPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBTogglePlugin,
} from "bootstrap-vue";
import ContentBlockConstructor from "@/bootstrap/components/content/block/constructor.vue";
import FormConstructor from "@/bootstrap/components/form/constructor.vue";
import BootstrapAppForm from "@/bootstrap/components/app-form/app-form.vue";
import AppFilter from "@/bootstrap/components/filter/app.vue";

store.registerModule(contentBlockModule.name, contentBlock);
Vue.use(VBTogglePlugin);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.mixin({
  methods: {
    $alert(message: any) {
      (this as any).$message(message);
    },
    async $getConfirm(text: any) {
      return await (this as any).$confirm(text);
    },
  },
});

Vue.component("ContentBlockConstructor", ContentBlockConstructor);
Vue.component("FormConstructor", FormConstructor);
Vue.component("BootstrapAppForm", BootstrapAppForm);
Vue.component("AppFilter", AppFilter);
