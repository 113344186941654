
import Vue from "vue";
import { BSidebar, BCollapse } from "bootstrap-vue";
import AppForm from "@/bootstrap/components/app-form/app-form.vue";
import { contentBlockModule } from "@/bootstrap/store/contentBlock";
import blocks from "@/bootstrap/content-blocks.json";
export default Vue.extend({
  name: "content-block-model-editor",
  components: { BSidebar, BCollapse, AppForm },
  data: () => ({
    module: contentBlockModule,
    block: null as any,
    blockMetaInfo: null as any,
  }),
  computed: {
    blockGroups() {
      return this.blockMetaInfo?.groups ?? [];
    },
    blockFields() {
      return this.blockMetaInfo?.fields ?? [];
    },
  },
  created() {
    if (!this.block)
      this.$store.commit(contentBlockModule.mutations.closeModelEditor);
  },
  methods: {
    sidebarEvent(event) {
      if (!event) {
        this.$store.commit(contentBlockModule.mutations.closeModelEditor);
        return;
      }

      this.block = this.$store.getters[contentBlockModule.getters.block];

      this.blockMetaInfo = (this as any).getBlockMetaInfo(this.block);
    },
    async undate() {
      if (!this.block) return;
      const { state, commit } = this.$store;
      const dataSource = state[contentBlockModule.name].dataSource;
      if (!dataSource) throw new Error("dataSource не передан");
      const id = this?.block?.id;

      const updatedData = await dataSource.updateField(id, [
        {
          fieldName: "model",
          fieldValue: this.block.model,
        },
      ]);
      commit(contentBlockModule.mutations.updateBlock, updatedData);
    },
    getBlockMetaInfo(block) {
      if (!block) return null;
      const group = blocks.find((x: any) => x.group === block.group);
      if (!Array.isArray(group?.blocks)) return null;
      const metaInfo = (group as any).blocks.find(
        (b: any) => b.name === block.name,
      ).metaInfo?.model;

      return metaInfo ?? null;
    },
  },
});
