
import Ace from "@/components/helpers/ace.vue"
import { Vue, Component } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";

@Component({
  mixins: [editor],
  components: { ObjectDataTable, Ace },
})
export default class ProductLinkInlineEditor extends Vue {
  dialog = false;
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

}
