
import { Component, Ref } from "vue-property-decorator";
import Provider from "./provider";
@Component
export default class DefaultProvider extends Provider {
  @Ref("form") form!: any;

  public async validate(): Promise<boolean> {
    if (this.field.editor == "slot") return await true;
    const form = this.form?.__vue__?.$children[0];
    if (!form) throw new Error("не верный provider для поля" + this.field.name);
    const _valid = await form.validate();
    return _valid;
  }

  public reset(): void {
    if (this.field.editor == "slot") return;
    const form = this.form?.__vue__?.$children[0];
    if (!form) throw new Error("не верный provider для поля" + this.field.name);
    form.reset();
  }
}
