
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
// import { TiptapVuetify, Heading,Image, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
@Component({
  components:{VueEditor}
})
export default class EditorSimpleHtml extends Vue {
  @Prop() value!: string;
  // nativeExtensions = [
  //   new class extends Extension {
  //     keys () {
  //       return {
  //         Enter (state, dispatch, view) {
  //           console.log('Enter!')
  //           // return true prevents default behaviour
  //           return true
  //         }
  //       }
  //     }
  //   }()
  // ]
  // extensions = [
  //   History,
  //   Blockquote,
  //   Link,
  //   Underline,
  //   Strike,
  //   Italic,
  //   ListItem,
  //   BulletList,
  //   [Image, {
  //     options: {
  //       imageSourcesOverride: true,
  //       imageSources: [
  //         { component: TiptapFileSelector, name: 'Выбрать изображение' }
  //       ]
  //     }
  //   }],
  //   OrderedList,
  //   [Heading, {
  //     options: {
  //       levels: [1, 2, 3]
  //     }
  //   }],
  //   Bold,
  //   Code,
  //   HorizontalRule,
  //   Paragraph,
  //   HardBreak
  // ]
}
