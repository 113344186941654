export const initConsentRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/consents",
          name: "consent",
          component: () => import("@/pages/manage/consents/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.consentManager,
            ],
          },
        },
        {
          path: "/manage/consents/:id",
          name: "consent-id",
          component: () => import("@/pages/manage/consents/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.consentManager,
            ],
          },
        },
      ];
