export const initEventRegistrationRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/event/:eventId/event-registrations",
          name: "event-registration",
          component: () =>
            import("@/pages/manage/events/zones/registrations/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
      ];
