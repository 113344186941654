export const initProjectRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/projects/audio",
          name: "project-audio",
          component: () => import("@/pages/manage/projects/audio/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/projects/audio/:id",
          name: "AudiofileProject-id",
          component: () => import("@/pages/manage/projects/audio/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/projects/messanger",
          name: "project-messanger",
          component: () =>
            import("@/pages/manage/projects/messanger/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },

        {
          path: "/manage/projects/messanger/:id",
          name: "MessangerProject-id",
          component: () => import("@/pages/manage/projects/messanger/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
