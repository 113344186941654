export const initEventBroadcastRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/event/:eventId/event-broadcasts",
          name: "event-broadcast",
          component: () =>
            import("@/pages/manage/events/zones/broadcasts/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
        {
          path: "/manage/event/:eventId/event-broadcasts/:broadcastId",
          name: "event-broadcast-id",
          component: () =>
            import("@/pages/manage/events/zones/broadcasts/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
      ];
