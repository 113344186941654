import Vue from "vue";

export default Vue.extend({
  props: {
    provider: Object,
    model: Object,
    actions: Array,
    nested: Array,
    customValidators: Object,
    field: Object,
    editMode: Boolean,
  },
  methods: {
    async validate(): Promise<boolean> {
      const form = (this as any).$refs.form?.__vue__?.$children[0];
      if (!form)
        throw new Error(
          "не верный provider для поля" + (this as any).field.name,
        );
      const _valid = await form.validate();
      return _valid;
    },
  },
});
