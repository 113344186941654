import {
  VuexModule,
  Module,
  getModule,
  Mutation, Action,
} from "vuex-module-decorators";
import store from "@/store";
import { ErrorModel } from "@/models/models";
import _ from "lodash";

@Module({ store, namespaced: true, name: "appConfigModule", dynamic: true })
class AppConfigModule extends VuexModule {
  public loading: boolean = false;
  public error!: ErrorModel;
  public config: any = null;
  public navigations: any = [];

  @Mutation
  public updateNavigations(userRoles: any[]): void {
    const configCopy = JSON.parse(JSON.stringify(this.config));
    this.navigations = configCopy.navigation.filter((n: any) => {

      const checkRole = (c: any) =>
        _.intersection(c.roles, userRoles)?.length > 0;
      n.children = n.children.filter(checkRole);
      return n.children.length > 0;
    });
  }

  @Mutation
  public setError(error: ErrorModel): void {
    this.error = error;
  }

  @Mutation
  public setConfig(config: any): void {
    this.config = config;
  }

  @Mutation
  public setLoading(): void {
    this.loading = true;
  }

  @Mutation
  public clearLoading(): void {
    this.loading = false;
  }

  @Action
  public getView(name:string){
    const views = this.config?.views;
    if(!Array.isArray(views)) return null;
    return views.find((v:any) => v.name == name) ?? null;
  }
}
const appConfigModule = getModule(AppConfigModule);

export default appConfigModule;
