export const initBookletConfigurationRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/event/:eventId/booklet-configurations",
          name: "booklet-configuration",
          component: () => import("@/pages/manage/events/zones/booklet-configurations/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
        {
          path: "/manage/event/:eventId/booklet-configurations/:bookletConfigurationId",
          name: "booklet-configuration-id",
          component: () => import("@/pages/manage/events/zones/booklet-configurations/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
      ];
