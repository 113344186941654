export const initProductModificationRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/commodities/:productId/modifications",
          name: "product-modification",
          component: () =>
            import("@/pages/manage/commodities/zones/modifications/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/commodities/:productId/modifications/:id",
          name: "product-modification-id",
          component: () =>
            import("@/pages/manage/commodities/zones/modifications/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
