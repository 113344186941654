
import { Vue, Component, Ref } from "vue-property-decorator";
import editor from "./editor";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import _ from "lodash";
import { FolderDataSource } from "@/data/Tree/FolderDataSource";
import { findElementInTree } from "@/cms-services/helpers";

@Component({
  mixins: [editor],
  components: { DataEditorDialog },
})
export default class ResourceInlineEditor extends Vue {
  @Ref("creator") creator!: DataEditorDialog;
  dialog = false;
  notifications = false;
  sound = true;
  widgets = false;
  field: any;
  loaded: boolean = false;
  contextMenu: any = {
    visible: false,
    x: null,
    y: null,
    data: null,
  };
  test = []
  model: any = {
    parentId: null,
    caption: null,
    description: null,
    name: null,
  };

  fields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
      },
      name: "caption",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "systemname",
      attrs: {
        label: "Системное имя",
        type: "text",
      },
      name: "name",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
      ],
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
      validations: [],
    },
  ];
  $message: any;
  $confirm: any;
  dataSource: any;

  get items(): any {
    return this.folderDataSource.items;
  }

  get hasItems() {
    return this.folderDataSource?.items?.length > 0;
  }

  get folders() {
    return [this.dataSource.model[this.field.name]];
  }

  get allfolder() {
    return findElementInTree(
      this.dataSource.model[this.field.name],
      this.folderDataSource.items
    );
  }

  folderDataSource: FolderDataSource = new FolderDataSource({
    className: "",
    rootFolder: "",
  });


  async created() {
    this.folderDataSource.className = this.field?.config?.className;
    this.folderDataSource.rootFolder =
      this.field?.config?.rootFolder ?? "mainFolder";
    await this.folderDataSource.get();
    this.loaded = true;
  }

  async openContextMenu(data = null, e) {
    this.contextMenu.visible = false;
    this.contextMenu.x = e.clientX;
    this.contextMenu.y = e.clientY;
    await this.$nextTick();
    this.contextMenu.data = data;
    this.contextMenu.visible = true;
  }

  async add() {
    const _data: any = await this.creator.create(this.model);
    if (!_data) return;
    try {
      _data.parentId = this.contextMenu?.data?.id;
      await this.folderDataSource.add(_data);
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async update() {
    const _data: any = await this.creator.update(this.contextMenu.data);
    if (!_data) return;
    try {
      await this.folderDataSource.update(_data);
      this.$message("Успешно Обновлено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async remove() {
    if (this.contextMenu.data?.children?.length > 0) {
      return this.$message(
        "Нельзя удалять элементы у которых есть дочерние элементы",
        "error"
      );
    }

    try {
      await this.folderDataSource.remove(this.contextMenu.data.id);
      this.$message("Успешно удалён");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async addRootElement() {
    await this.folderDataSource.add({
      parentId: null,
      caption: "Все",
      name: "mainFolder",
    });
  }
}
