export const initProductSeriesRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/productseries",
          name: "productseries",
          component: () => import("@/pages/manage/productseries/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/productseries/:id",
          name: "productseries-id",
          component: () => import("@/pages/manage/productseries/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
