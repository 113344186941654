
import { Vue, Component } from "vue-property-decorator";
import editor from "@/components/common/inline-editor/editor";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class linkedpricelistitem extends Vue {
  dialog = false;
  loaded = false;
  dataSource: any;
  catalogMetadata = {
    name: "priceCatalogId",
    type: "string",
    description: null,
    caption: "Каталог цен",
    editor: "componentselector",
    priority: 100,
    group: "linkedcomponents",
    visibleInTable: false,
    validations: [],
    config: {
      className: "PriceCatalog",
    },
  };

  productPriceListItemDataSource: ListDataSource = new ListDataSource({
    className: "ProductPriceListItem",
    config: {},
  });

  priceCatalogDataSource: ObjectDataSource = new ObjectDataSource({
    className: "PriceCatalog",
    config: {},
    id: 0,
  });
  $message: any;

  isActive(item: any) {
    return this.productPriceListItemDataSource.items.some(
      (b: any) => b.priceListItemId == item.id
    );
  }

  async setActive(item: any, event: any) {
    if (!event) {
      if (!this.isActive(item)) return;
      const link = this.productPriceListItemDataSource.items.find(
        (i) => i.priceListItemId == item.id
      );
      await this.productPriceListItemDataSource.remove(link.id);
      this.$message("Успешно удалено");
      return;
    }
    await this.productPriceListItemDataSource.add({
      componentId: this.dataSource.id,
      priceListItemId: item.id,
    });
    this.$message("Цена успешно добавлено");
  }

  async updateLinks() {
    if (!this.dataSource.model.priceCatalogId) {
      this.$message("Выберите каталог", "error");
      return;
    }
    this.productPriceListItemDataSource.config.filter = JSON.stringify([
      {
        fieldName: "componentId",
        fieldValue: this.dataSource.id,
      },
    ]);
    this.priceCatalogDataSource.id = this.dataSource.model.priceCatalogId;
    await Promise.all([
      this.priceCatalogDataSource.get(),
      this.productPriceListItemDataSource.get(),
    ]);
  }

  async open() {
    await this.updateLinks();
    this.dialog = true;
    this.loaded = true;
  }
}
