var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"form"},[_vm._t("default",null,{"editor":{
      name: _vm.field.editor,
      model: _vm.model[_vm.field.name],
      actions: _vm.actions,
      fields: _vm.nested[_vm.field.config.fields],
      editMode: _vm.editMode,
      nested: _vm.nested,
    }})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }