export class Filter {
  public fieldName: string;
  public fieldValue: string | number | boolean;

  constructor(name: string, value: string | number | boolean) {
    this.fieldName = name;
    this.fieldValue = value;
  }
}

export class FilterUtils {
  public filters: Filter[] = [];
  public get filterString(): string {
    return JSON.stringify(this.filters);
  }

  constructor(filters: Filter[]) {
    this.filters = filters;
  }

  public add(filter: Filter) {
    this.filters.push(filter);
    return this;
  }

  public remove(name: string) {
    const index = this.filters.findIndex((f: Filter) => f.fieldName === name);
    if (index == -1) throw new Error("Не найден");
    this.filters.splice(index, 1);
    return this;
  }

  public update(name: string, value: string | number | boolean) {
    const filter = this.filters.find((f: Filter) => f.fieldName === name);
    if (!filter) throw new Error("Не найден");
    filter.fieldValue = value;
    return this;
  }

  public clear() {
    this.filters = [];
    return this;
  }
}
