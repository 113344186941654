var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',_vm._b({},'v-row',_vm.attrs,false),[_vm._l((_vm.fieldList),function(field){return [(_vm.canShow(field))?_c('v-col',_vm._b({key:field.id},'v-col',_vm.getGrid(field.grid),false),[_vm._t("default",null,{"provider":{
          name: _vm.getProviderName(field),
          field,
          model: _vm.model,
          actions: _vm.actions,
          customValidators: _vm.customValidators,
          editMode: _vm.editMode,
          nested: _vm.nested,
        }})],2):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }