export const initClientWebOrderRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/client-web-orders",
          name: "client-web-order",
          component: () => import("@/pages/manage/client-web-orders/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.supportManager,
              config.roles.sureSmileManager,
            ],
          },
        },
        {
          path: "/manage/client-web-orders/:id",
          name: "client-web-order-id",
          component: () => import("@/pages/manage/client-web-orders/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.supportManager,
              config.roles.sureSmileManager,
            ],
          },
        },
      ];
