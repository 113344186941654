
import {
  mdiMagnify,
  mdiDotsVertical,
  mdiPlusCircleOutline,
  mdiPen,
  mdiTrashCan,
  mdiFolderOpen,
  mdiFolder,
  mdiClose,
} from "@mdi/js";
import _ from "lodash";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import { IBaseConfig } from "@/ioc/types";
import { getInstaceStateName } from "@/cms-services/helpers";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import ObjectExpansionEditor from "@/components/helpers/object-expansion-editor.vue";
import MenuLoader from "./menu-loader.vue";
@Component({
  components: { ObjectExpansionEditor, MenuLoader },
})
export default class DataList extends Vue {
  @Ref("menuLoaders") menuLoaders!: MenuLoader;
  @Prop() customHeaders!: any[];
  @Prop() dataSource!: ListDataSource;
  @Prop({ default: true }) activatable!: boolean;
  @Prop({ default: "Данных нет" }) emptyCaption!: string;
  @Prop({ default: null }) customStateColor!: Function;
  @Prop({}) propertiesOverrideInfo!: any;
  @Prop({}) propertyGroupsOverrideInfo!: any;
  @Prop({ default: "get" }) getMethodKeyName!: string;
  @Prop({ default: null }) filterPredicate!: Function;
  getInstaceStateName: any = getInstaceStateName;
  drawer: boolean = false;
  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    close: mdiClose,
  };

  options: any = {
    page: this.dataSource.config.pageIndex,
    itemsPerPage: this.dataSource.config.pageSize,
  };
  loading: boolean = false;
  headers: any = [];
  $getEditorName: any;
  $confirm: any;
  $message: any;

  objectDataSource: ObjectDataSource = new ObjectDataSource({
    className: "",
    config: {},
    id: 0,
  });
  drawerLoaded: boolean = false;

  get filteredItems() {
    if (!this.filterPredicate) return this.dataSource.items;
    return this.dataSource.items.filter((i: any) => this.filterPredicate(i));
  }

  async executeAsyncRequest(callBack: Function, rowKey: number) {
    const component = (this.menuLoaders as any).find(
      (c: any) => c._props?.rowKey == rowKey,
    );

    if (component) {
      await component.execute(callBack);
    }
  }

  async getObject(item) {
    this.drawerLoaded = false;
    this.objectDataSource.id = item.id;
    await this.objectDataSource[this.getMethodKeyName]();
    this.drawerLoaded = true;
    this.drawer = true;
  }

  closeDrawer() {
    const cb = (x: any) => +x.id === +this.objectDataSource.id;
    const item = this.dataSource.items.find(cb);
    Object.assign(item, this.objectDataSource.model);
    this.drawer = false;
  }

  getItemStateName(item): string {
    return item.instanceStateId === 4 ? "Деактивировать" : "Активировать";
  }

  getInstanceStateColor(instanceStateId) {
    if (this.customStateColor != null) {
      return this.customStateColor(instanceStateId);
    }
    switch (instanceStateId) {
      case 4: return "primary";
      case 1: return "warning";
      case 11: return "success";
      default: return "default";
    }
  }

  toPascalCase = (str: any) => _.startCase(_.camelCase(str)).replace(/ /g, "");

  updateOptions(value: any) {
    const config: IBaseConfig = {};
    if (value.sortBy?.length > 0) {
      config.orderFieldName = this.toPascalCase(value.sortBy ?? "Id");
    }

    if(value?.sortDesc){
      config.orderFieldDirection = value?.sortDesc ? "DESC" : "ASC";
    }

    if (value?.page) {
      config.pageIndex = value.page;
    }

    if (value?.itemsPerPage) {
      config.pageSize = value.itemsPerPage === -1 ? this.dataSource.total : value.itemsPerPage;
    }

    this.refreshData(config);
  }

  async created() {
    this.headers = this.getHeaders(
      this.dataSource.metadata?.properties,
      this.customHeaders,
    );

    console.log(this.headers)

    this.objectDataSource.className = this.dataSource.className;
    this.objectDataSource.metadata = this.dataSource.metadata;
    this.$emit("header-init", this.headers);
  }

  routeToPage(id: string) {
    if (!!this.$listeners.route) {
      this.$emit("route", id);
      return;
    }

    this.$router.push({
      name: this.dataSource.className + "-id",
      params: { id },
    });
  }



  getField(header) {
    const field = this.dataSource?.metadata?.properties.find(
      (f: any) => f.name === header.value,
    );
    return field ? { ...field, readonly: true } : null;
  }

  getHeaders(fields: any, customHeaders: any[]): any {
    const actions = [
      {
        value: "actions",
        sortable: false,
        align: "right",
      },
    ];
    if (customHeaders?.length > 0) return [...customHeaders, ...actions];
    if (!(fields?.length > 0)) throw new Error("Метаданные не определены");

    return [
      ...fields
        .filter((f: any) => f.visibleInTable)
        .sort((p: any, n: any) => p.priority - n.priority)
        .map((f: any) => ({
          text: f.caption,
          value: f.name,
          sortable: !["instanceStateId"].includes(f.name),
        })),
      ...actions,
    ];
  }

  async remove(item) {
    const confirm = await this.$confirm({
      title: "Вы уверены что хотите удалить всё?",
      text: "Восстановление удаленных данных невозможно",
    });
    if (!confirm) return;
    try {
      await this.dataSource.remove(item.id);
      this.$message("Успешно удалено");
      await this.refreshData();
    } catch (error: any) {
      this.$message("Не удалось удалить", "error");
    }
  }

  async changeItemState(item) {
    const state = item?.instanceStateId;
    await this.dataSource.updateField(item.id, [
      {
        fieldName: "instanceStateId",
        fieldValue: state === 4 ? 1 : 4,
      },
    ]);
    this.$message("Статус успешно изменён");
  }

  async refreshData(config: IBaseConfig = {}) {
    this.loading = true;
    Object.assign(this.dataSource.config, config);
    await this.dataSource[this.getMethodKeyName]();

    this.loading = false;
  }
}
