export const initCommodityRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/commodities",
          name: "commodity",
          component: () => import("@/pages/manage/commodities/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/commodities/:id",
          name: "commodity-id",
          component: () => import("@/pages/manage/commodities/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
