export const initEventRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/events",
          name: "event",
          component: () => import("@/pages/manage/events/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors,config.roles.eventManagers],
          },
        },
        {
          path: "/manage/events/:id",
          name: "event-id",
          component: () => import("@/pages/manage/events/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors,config.roles.eventManagers],
          },
        },
      ];
