import { getValue } from "@/cms-services/helpers";
import { Vue, Prop } from "vue-property-decorator";

export default class Container extends Vue {
  @Prop() fields!: any;
  @Prop() model!: any;
  @Prop() actions!: any;
  @Prop() customValidators!: any;
  @Prop() editMode!: any;
  @Prop() nested!: any;
  @Prop() attrs!: any;

  getProviderName = (field: any) => field?.provider?.name ?? "default";
  getGrid = (grid: any) => grid ?? { cols: 12 };
  canShow(field) {
    const bindedFieldName = field?.bindedFieldName;
    const bindedFieldValue = field?.bindedFieldValue;
    if (!bindedFieldName) return true;
    return bindedFieldValue == getValue(this.model, bindedFieldName);
  }
}
