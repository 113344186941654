
import { Vue, Component, Prop } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";

@Component
export default class SecurityGroupDialog extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: () => [] }) excludedUserProfileIdx!: number[];
  resolve!: Function;

  userProfileFilterPredicate(up: any) {
    return !this.excludedUserProfileIdx.some((i) => i == up.id);
  }

  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
  });

  addByUserProfile(userProfile: any) {
    this.$emit("add-by-user-profile", userProfile.id);
  }
}
