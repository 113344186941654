export const initClinicRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/clinics",
          name: "clinic",
          component: () => import("@/pages/manage/clinics/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/clinics/:id",
          name: "clinic-id",
          component: () => import("@/pages/manage/clinics/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/clinics/:id/branches",
          name: "clinic-branch",
          component: () =>
            import("@/pages/manage/clinics/zones/branches/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/clinics/:id/branches/:branchId",
          name: "clinic-branch-id",
          component: () =>
            import("@/pages/manage/clinics/zones/branches/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
