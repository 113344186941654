
import Editor from "./editor";
export default Editor.extend({
  data: () => ({
    items: [],
    loaded: false,
  }),
  methods: {
    change() {
      (this as any).validate();
      (this as any).emit();
    },
    async onModelChanged() {
      const self: any = this;
      if (!(self.watchers?.length > 0)) return;
      for (const watcher of self.watchers) {
        const { changeModel, changeAttrs, changeItems } = watcher;
        if (!!changeModel) await self.actions[changeModel](self.model);
        if (!!changeItems)
          this.items = await self.actions[changeItems](self.model);
        if (!!changeAttrs)
          self.attrs = await self.actions[changeAttrs](self.model);
      }
    },
  },
  async created() {
    const self: any = this;
    this.items = await self.actions[self.config.getItems]();
    this.loaded = true;
  },
});
