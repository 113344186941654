
import { Vue, Component } from 'vue-property-decorator';
import editor from "@/components/common/inline-editor/editor";
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css'

@Component({
  mixins: [editor],
  components:{VSwatches}
})
export default class colorSelector extends Vue {

}
