export const initPersonRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/persons",
          name: "person",
          component: () => import("@/pages/manage/persons/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
        {
          path: "/manage/persons/:id",
          name: "person-id",
          component: () => import("@/pages/manage/persons/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
      ];
