import { ListDataSource } from "@/data/List/ListDataSource";
import blocks from "@/bootstrap/content-blocks.json";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
export default {
  namespaced: true,
  state: {
    blockPalleteVisible: false,
    contentId: null,
    componentDataSource:{} as ObjectDataSource,
    dataSource: {} as ListDataSource,
    insertIndex: null,
    parentId: null,
    addToEnd: null,
    blocks: blocks,
    configuratorVisible: false,
    block: null,
    modelEditorVisible: false,
  },
  mutations: {
    OPEN_BLOCK_PALLETE(state: any, payload: any) {
      state.dataSource = payload.dataSource;
      state.addToEnd = payload.addToEnd;
      state.insertIndex = payload.insertIndex;
      state.parentId = payload.parentId;
      state.blockPalleteVisible = true;
    },
    SET_CONTENT_ID(state: any, contentId: number) {
      state.contentId = contentId;
    },
    SET_COMPONENT_DATA_SOURCE(state: any, dataSource: any) {
      state.componentDataSource = dataSource;
    },
    CLOSE_BLOCK_PALLETE(state: any) {
      state.blockPalleteVisible = false;
      state.dataSource = null;
    },
    OPEN_BLOCK_CONFIGURATOR(state: any, payload: any) {
      if (!payload.dataSource) throw new Error("dataSource не передан");
      state.dataSource = payload.dataSource;
      state.block = payload.block;
      state.configuratorVisible = true;
    },
    OPEN_MODEL_EDITOR(state: any, payload: any) {
      if (!payload.dataSource) throw new Error("dataSource не передан");
      state.dataSource = payload.dataSource;
      state.block = payload.block;

      state.modelEditorVisible = true;
    },
    CLOSE_BLOCK_CONFIGURATOR(state: any) {
      state.configuratorVisible = false;
    },
    CLOSE_MODEL_EDITOR(state: any) {
      state.modelEditorVisible = false;
    },
    UPDATE_BLOCK(state: any, payload) {
      state.block = payload;
    },
  },
  actions: {
    async addBlock({ state }: any, block: any) {
      if (!state.dataSource) throw new Error("dataSource не определён");

      const { isOften, metaInfo, ...rest } = block;
      const parentId = JSON.parse(state.dataSource.config.filter).find(
        (x: any) => x.fieldName === "parentId",
      ).fieldValue;
      await state.dataSource.add(
        { ...rest, contentId: state.contentId, parentId },
        state.addToEnd,
        state.insertIndex,
      );
    },
  },
  getters: {
    blockPalleteVisible: (state: any) => state.blockPalleteVisible,
    contentId: (state: any) => state.contentId,
    blocks: (state: any) => state.blocks,
    configuratorVisible: (state: any) => state.configuratorVisible,
    modelEditorVisible: (state: any) => state.modelEditorVisible,
    block: (state: any) => state.block,
  },
};

const moduleName = "content-block";

export const contentBlockModule = {
  name: moduleName,
  satate: {
    dataSource: `${moduleName}/dataSource`,
    componentDataSource: `${moduleName}/componentDataSource`,
  },
  getters: {
    blockPalleteVisible: `${moduleName}/blockPalleteVisible`,
    contentId: `${moduleName}/contentId`,
    blocks: `${moduleName}/blocks`,
    configuratorVisible: `${moduleName}/configuratorVisible`,
    modelEditorVisible: `${moduleName}/modelEditorVisible`,
    block: `${moduleName}/block`,
  },
  actions: {
    addBlock: `${moduleName}/addBlock`,
  },
  mutations: {
    openBlockPallete: `${moduleName}/OPEN_BLOCK_PALLETE`,
    setContentId: `${moduleName}/SET_CONTENT_ID`,
    setComponentDataSource: `${moduleName}/SET_COMPONENT_DATA_SOURCE`,
    closeBlockPallete: `${moduleName}/CLOSE_BLOCK_PALLETE`,
    openBlockConfigurator: `${moduleName}/OPEN_BLOCK_CONFIGURATOR`,
    openModelEditor: `${moduleName}/OPEN_MODEL_EDITOR`,
    closeModelEditor: `${moduleName}/CLOSE_MODEL_EDITOR`,
    closeBlockConfigurator: `${moduleName}/CLOSE_BLOCK_CONFIGURATOR`,
    updateBlock: `${moduleName}/UPDATE_BLOCK`,
  },
};
