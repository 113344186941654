export const initEventSubscriberRoutes = (config: any, enabled: boolean = true) =>
    !enabled
    ? []
    : [
        {
          path: "/manage/event/:eventId/subscribers",
          name: "event-subscriber",
          component: () =>
            import("@/pages/manage/events/zones/subscribers/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        }
      ];
