var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"form"},[_vm._t("default",null,{"editor":{
      name: _vm.field.editor,
      model: _vm.model,
      actions: _vm.actions,
      field: _vm.field,
      editMode: _vm.editMode,
      customValidators: _vm.customValidators,
    }})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }