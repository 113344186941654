
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import _ from "lodash";

@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class ProductLinkInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  searchTimer: any = null;
  dialog: boolean = false;
  searchText: string = "";
  filterText: string = "";
  loaded: boolean = false;
  serviceListCopy: any = [];
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];
  field!: any;
  serviceDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: this.field?.config?.className ?? "service",
  });

  linkedServiceDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: this.field?.config?.className ?? "service",
  });
  companyBranchServiceDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "companyBranchService",
  });

  $message: any;

  updateservices() {
    let sourceItems = Array.from([...this.serviceDataSource.items]);

    for (const service of sourceItems) {
      const exists = this.dataSource.model?.services.some(
        (c: any) => c.serviceId == service.id,
      );
      if (exists) {
        const index = this.serviceDataSource.items.findIndex(
          (c: any) => c.id == service.id,
        );
        this.serviceDataSource.items.splice(index, 1);
      }
    }
    this.linkedServiceDataSource.items = this.dataSource.model?.services?.map(
      (cf: any) => this.serviceListCopy.find((f: any) => f.id == cf.serviceId),
    );
  }

  filterLinkedservice() {
    this.updateservices();
    if (!this.filterText) return;
    const re = new RegExp(this.filterText, "gi");
    this.linkedServiceDataSource.items =
      this.linkedServiceDataSource.items.filter((c: any) =>
        c.caption.match(re),
      );
  }

  async created() {
    await this.serviceDataSource.get();

    this.serviceListCopy = _.cloneDeep(this.serviceDataSource.items);
    this.linkedServiceDataSource.metadata = this.serviceDataSource.metadata;
    this.linkedServiceDataSource.total =
      this.dataSource.model?.services?.length;
    this.companyBranchServiceDataSource.items = this.dataSource.model?.services;
    this.updateservices();
    this.loaded = true;
  }

  async add(item: any) {
    await this.companyBranchServiceDataSource.add({
      companyBranchId: this.dataSource.id,
      serviceId: item.id,
    });

    await this.dataSource.get();
    this.updateservices();
    this.linkedServiceDataSource.total =
      this.linkedServiceDataSource.items.length;
    this.$message("Успешно добавлено");
  }

  async remove(item: any) {
    const cf = this.dataSource.model?.services?.find(
      (c: any) => c.serviceId == item.id,
    );
    await this.companyBranchServiceDataSource.remove(cf.id);
    const index = this.linkedServiceDataSource.items.findIndex(
      (c: any) => c.id === item.id,
    );
    if (index === -1) throw new Error("не найден");
    this.linkedServiceDataSource.items.splice(index, 1);
    this.serviceDataSource.items.push(item);
    this.linkedServiceDataSource.total =
      this.linkedServiceDataSource.items.length;

    this.$message("Успешно удалено");
  }

  @Watch("searchText")
  debounceSearch(value: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      this.serviceDataSource.config.filter = JSON.stringify([
        {
          fieldName: "searchText",
          fieldValue: value,
        },
      ]);
      await this.serviceDataSource.get();
      this.updateservices();
    }, 1000);
  }
}
