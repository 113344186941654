
import { Vue, Component } from "vue-property-decorator";
import DataItem from "./item";
import appConfigModule from "@/store/plugins/appConfig";
import DefaultComponentItem from "@/components/views/default-component-item.vue";
import Person from "@/components/views/person.vue"
import Doctor from "@/components/views/doctor.vue"
import EventView from "@/components/views/event.vue";
@Component({
  mixins: [DataItem],
  components:{DefaultComponentItem,Person, Doctor,"event":EventView}
})
export default class ComponentDataItem extends Vue {
  customView: any;
  viewName!: string;
  async mounted(){
    this.customView = await appConfigModule.getView(this.viewName) ?? {
      name:"default",
      view:"default-component-item"
    };
  }
}
