import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from 'vuex-module-decorators';
import store from '@/store';

@Module({ store, dynamic: true, namespaced: true, name: 'confirm' })
class ConfirmModule extends VuexModule {
  public visible = false;
  public text = '';
  public title = '';
  public resolve: any;

  @Action({ rawError: true })
  public async open(confirm: any) {
    return new Promise<void>((resolve: any) => {
      this.initDialog({ confirm, resolve });
    });
  }

  @Mutation
  private initDialog({ confirm, resolve }: { confirm: any; resolve: any }) {
    this.visible = true;
    this.resolve = resolve;
    if (typeof confirm === 'string') {
      this.title = confirm;
      this.text = '';
      return;
    }

    this.title = confirm?.title ?? '';
    this.text = confirm?.text ?? '';
  }

  @Mutation
  public accept() {
    this.resolve(true);
    this.visible = false;
  }

  @Mutation
  public decline() {
    this.resolve(false);
    this.visible = false;
  }
}

export default getModule(ConfirmModule);
