
import draggable from "vuedraggable";
import Vue from "vue";
import { mapGetters } from "vuex";
import { getContentBlockDataSource } from "@/bootstrap/helper";
import ContentBlockText01 from "./text/01.vue";
import ContentBlockText02 from "./text/02.vue";
import ContentBlockImage01 from "./img/01.vue";
import ContentBlockImage02 from "./img/02.vue";
import ContentBlockImage03 from "./img/03.vue";
import ContentBlockDocument01 from "./document/01.vue"

import { contentBlockModule } from "@/bootstrap/store/contentBlock";
interface IData {
  dataSource: any;
  loaded: boolean;
}
interface IProps {
  parentId: any;
  canEdit: boolean;
}
interface IComputed {
  contentId: number;
}
interface IMethods {
  changeItems(event: any): Promise<void>;
  chooseBlock(index: number, addToEnd: boolean): void;
}
export default Vue.extend<IData, IMethods, IComputed, IProps>({
  name: "content-block-canvas",
  components: {
    draggable,
    ContentBlockText01,
    ContentBlockText02,
    ContentBlockImage01,
    ContentBlockImage02,
    ContentBlockImage03,
    ContentBlockDocument01
  },
  props: {
    parentId: {
      type: [Number, String],
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dataSource: {} as any,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      contentId: contentBlockModule.getters.contentId,
    }),
  },
  async created() {
    this.dataSource = getContentBlockDataSource(this.contentId, this.parentId);
    await this.dataSource.get();
    this.loaded = true;
  },
  methods: {
    async changeItems(event: any) {
      const id = event?.added?.element?.id;
      if (!!id) {
        await this.dataSource.updateField(id, {
          fieldName: "parentId",
          fieldValue: this.parentId,
        });
      }
      await this.dataSource.changePriority();
    },

    chooseBlock(index: number, addToEnd: boolean) {
      this.$store.commit(contentBlockModule.mutations.openBlockPallete, {
        dataSource: this.dataSource,
        addToEnd,
        insertIndex: index,
      });
    },
  },
});
