
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class StringInlineEditor extends Vue {
  public get maskValue(){
    return (this as any).field?.config?.mask ?? (this as any).field?.mask
  }
}
