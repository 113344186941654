
import Vue from "vue";
import { BDropdownItem } from "bootstrap-vue";
import { getGUID } from "@/cms-services/helpers";
import ContentBlockText01 from "./text/01.vue";
import ContentBlockText02 from "./text/02.vue";
import ContentBlockGrid01 from "./grid/01.vue";
export default Vue.extend({
  name: "content-block-type",
  components: {
    BDropdownItem,
    ContentBlockText01,
    ContentBlockText02,
    ContentBlockGrid01,
  },
  props: { block: Object },
  data: () => ({
    guid: getGUID(),
  }),
});
