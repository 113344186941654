import Vue from "vue";
import axios from "axios";
import {
  IBaseConfig,
  IObjectDataSourceConfig,
  IUpdateField,
} from "@/ioc/types";
import { getGUID, toQueryString } from "@/cms-services/helpers";
import { checkToken } from "@/cms-services/token";

export class ObjectDataSource {
  public config: IBaseConfig;
  public metadata!: any;
  public model: any;
  public loading: boolean = false;
  public id!: number;
  public className: any;
  get baseUrl(): string {
    return `/manage/${this.className}/${this.id}`;
  }

  constructor({ config, className, id, model }: IObjectDataSourceConfig) {
    checkToken();
    this.className = className;
    this.config = config;
    this.id = id;
    this.model = model;
  }

  async getMetaData() {
    try {
      const { data } = await axios.get(`/manage/${this.className}/metadata/`);
      const groups = data.groups.map((g: any) => ({ ...g, id: getGUID() }));
      const properties = data.properties.map((p: any) => ({
        ...p,
        id: getGUID(),
      }));
      Vue.set(this, "metadata", { groups, properties });
    } catch (err) {
      throw err;
    }
  }

  async uploadDefaultImage(formData: any) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/upload/defaultImage`,
        formData.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        },
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  public async updateImage(base64: string, imageId:number): Promise<any> {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.put(`${this.baseUrl}/image/${imageId}`, base64, config);
      return data;
    } catch (error) {
      throw error;
    }
  }

  public getMetaDataByName(name: string) {
    const field = this.metadata.properties.find((i: any) => i.name === name);
    if (!field) throw new Error("Мета данные не найдены");
    return field;
  }
  async updateField(updateData: IUpdateField) {
    try {
      const { data } = await axios.patch(this.baseUrl, [updateData]);
      this.model[updateData.fieldName] = updateData.fieldValue;
      return data;
    } catch (err) {
      throw err;
    }
  }

  async uploadFiles(
    formData: FormData,
    fieldName: string = "linkedFiles",
    folderId?: number,
    onProgress?: Function,
    file?: File,
  ) {
    try {
      let url = `${this.baseUrl}/files/upload`;
      if (folderId != null) {
        url += toQueryString({ folderId });
      }
      const { data } = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          if (!onProgress) return;
          onProgress(e, file);
        },
      });

      this.model[fieldName].push(data);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async clearFile(event: any) {
    try {
      await axios.delete(
        `${this.baseUrl}/file/clear`,
      );
      return null;
    } catch (err) {
      throw err;
    }
  }
  async uploadFile(event: any) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/file/upload`,
        event.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress(e) {
            event.onProgress(e, event.file);
          },
        },
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async deleteFile(id: number, fieldName: string = "linkedFiles") {
    if (!(this.model?.[fieldName]?.length > 0))
      throw new Error("linkedFiles не определены");
    const index = this.model?.[fieldName].findIndex((i: any) => +i.id === +id);
    if (index === -1) throw new Error("елемент не найден");
    try {
      await axios.delete(`/manage/attachedFile/${id}`);
      this.model?.[fieldName].splice(index, 1);
    } catch (err) {
      throw err;
    }
  }

  async get() {
    try {
      const _url = `${this.baseUrl}/${toQueryString(this.config)}`;
      const { data } = await axios.get(_url);
      Vue.set(this, "model", data);
      this.loading = true;
    } catch (error) {
      throw error;
    }
  }

  async update() {
    try {
      const { data } = await axios.put(`${this.baseUrl}`,this.model);
     this.model = data;
      this.loading = true;
    } catch (error) {
      throw error;
    }
  }
}

export class DocumentObjectDataSource extends ObjectDataSource {
  constructor(id: number) {
    super({
      config: {},
      className: "document",
      id,
    });
  }

  public async convert(folders: any) {
    try {
      const { data } = await axios.post(`${this.baseUrl}/convert`, folders);
      this.model = data;
    } catch (error) {
      throw error;
    }
  }
}
