
import {
  mdiArrowAll,
  mdiCog,
  mdiPen,
  mdiPlus,
  mdiStar,
  mdiTrashCan,
  mdiUpload,
} from "@mdi/js";
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import draggable from "vuedraggable";
import { ContentBlockFileDataSource } from "@/data/List/ContentBlockFileDataSource";
import ContentBlockFileList from "@/bootstrap/components/content/block/file/list.vue";
import FileUploader from "@/components/file-uploader.vue";
@Component({
  components: {
    ImageEditor,
    DataEditor,
    draggable,
    ContentBlockFileList,
    FileUploader,
  },
})
export default class ResourceInlineEditor extends Vue {
  @Prop() contentBlockId!: number;
  @Prop() dataSource!: ContentBlockFileDataSource;
  @Ref("fileList") fileList!: ContentBlockFileList;

  resolve!: Function;

  $confirm: any;

  icons: any = {
    plus: mdiPlus,
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    star: mdiStar,
  };

  dialog = false;
  $message: any;

  public async getFile() {
    this.dialog = true;
    await this.$nextTick();

    const image = await this.fileList.getImage();
    this.dialog = false;
    return image;
  }
}
