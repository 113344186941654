
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class album extends Vue {
  @Prop() item!:any
  @Prop() isActive!:boolean;

  get alertColor(){
    return this.isActive ? "success" : "default"
  }

  get textColor(){
    return this.isActive ? "white--text" : ""
  }
}
