export const initPriceCatalogRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/price-catalogs",
          name: "price-catalog",
          component: () => import("@/pages/manage/price-catalogs/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
        {
          path: "/manage/price-catalogs/:id",
          name: "price-catalog-id",
          component: () => import("@/pages/manage/price-catalogs/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
        {
          path: "/manage/price-catalogs/:id/zones/price-lists/:priceListId",
          name: "price-catalog-id-pricelist",
          component: () => import("@/pages/manage/price-catalogs/zones/price-list/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [
              config.roles.administrators,
              config.roles.siteEditors,
              config.roles.eventManagers,
            ],
          },
        },
      ];
