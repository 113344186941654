export const initProfessionalAreaRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/professional-area",
          name: "professional-area",
          component: () => import("@/pages/manage/professional-area/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/professional-area/:id",
          name: "professional-area-id",
          component: () => import("@/pages/manage/professional-area/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
