
import { getGUID } from "@/cms-services/helpers";
import { Component } from "vue-property-decorator";
import Container from "./container";

@Component
export default class DefaultContainer extends Container {
  fieldList: any = [];

  created() {
    if (!this.fields) throw new Error("Настройки полей не переданы");
    this.fieldList = this.fields.map((f: any) => ({
      ...f,
      id: getGUID(),
      errorMessages: [],
      successMessages: [],
    }));
  }
}
