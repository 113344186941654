
import Editor from "./editor";
import AppForm from "@/bootstrap/components/app-form/app-form.vue";
export default Editor.extend({
  components: { AppForm },
  computed: {
    fields() {
      return [
        {
          editor: "number",
          attrs: {
            label: this.editor?.min?.label,
            min: this.editor?.min?.value,
            max: this.editor?.max?.value,
          },
          name: "min",
          grid: { class: "col-6" },
          validations: [
            {
              validator: "min",
              value: this.editor?.min?.value,
              errorMessage: `Поле не может содержать меньше чем ${this.editor?.min?.value} символов`,
            },
          ],
          config: {},
          watchers: [],
        },
        {
          editor: "number",
          attrs: {
            label: this.editor?.max?.label,
            min: this.editor?.min?.value,
            max: this.editor?.max.value,
          },
          name: "max",
          grid: { class: "col-6" },
          validations: [
            {
              validator: "max",
              value: this.editor?.max.value,
              errorMessage: `Поле не может содержать больше чем ${this.editor?.max.value} символов`,
            },
          ],
          config: {},
          watchers: [],
        },
      ];
    },
  },
});
