
import Vue from "vue";
import EditorString from "./editor/string.vue";
import EditorNumber from "./editor/number.vue";
import EditorRange from "./editor/range.vue";

export default Vue.extend({
  name: "app-filter",
  components: { EditorString, EditorNumber, EditorRange },
  props: {
    groups: Array,
  },
  data: () => ({}),
  methods: {
    getCssClassName(field) {
      return field?.editor?.attrs?.class ?? "col-md-12";
    },
  },
});
