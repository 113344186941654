
import { getGUID } from "@/cms-services/helpers";
import Container from "./container";
export default Container.extend({
  name: "default-container",
  data: () => ({
    fieldList: [] as any,
  }),
  created() {
    this.initFields(this.fields);
  },
  watch: {
    fields: {
      deep: true,
      handler(value: any) {
        this.initFields(value);
      },
    },
  },
  methods: {
    initFields(fields: any) {
      if (!fields) throw new Error("Настройки полей не переданы");
      this.fieldList = new Array(
        ...fields.map((f: any) => ({
          ...f,
          id: getGUID(),
          errorMessages: [],
          successMessages: [],
        })),
      );
    },
  },
});
