import { render, staticRenderFns } from "./linkedservice.vue?vue&type=template&id=4fd6a12d"
import script from "./linkedservice.vue?vue&type=script&lang=ts"
export * from "./linkedservice.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports