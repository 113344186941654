
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import {ListDataSource} from "@/data/List/ListDataSource";
import userModule from "@/store/modules/user";
import axios from "axios";
import PersonSelector from "@/components/person-selector.vue";

@Component({
  components: {PersonSelector}
})
export default class EventView extends Vue {
  @Prop() dataSource!:ObjectDataSource
  dialog = false
  waitingVisible = false;
  registrationVisible = false;
  subscribtion:any = null;
  personSelector = false
  loaded = false;
  $message:any


  get shortProps(){
    return this.shortProperties.map(up => this.properties.find(p => p.name == up))
  }

  get canSubscribe(){
    return userModule.roles.some(r => ["Administrators", "Site Editors", "Event Managers"].includes(r))
  }

  get props(){
    return this.usedProperties.map(up => this.properties.find(p => p.name == up))
  }

  get waitingCount(){
    return this.registrationDataSource.items.filter(r => r.instanceStateId == 12).length;
  }

  get waitings(){
    return this.registrationDataSource.items.filter(r => r.instanceStateId == 12);
  }

  get registrations(){
    return this.registrationDataSource.items.filter(r => r.instanceStateId == 4);
  }

  async subscribe(){
    await axios.post(`/manage/EventSubscriber/`,{
      eventId:this.dataSource.id,
      userProfileId:userModule.profile.id
    })
    const {data} = await axios.get(`/manage/event/${this.dataSource.id}/subscribe/${userModule.profile.id}/check/`)
    this.subscribtion = data;
  }

  async cancelSubscribe(){
    await axios.delete(`/manage/EventSubscriber/${this.subscribtion.id}`)
    this.subscribtion = null;
  }

  async changeState(item:any, stateId:number){
    await this.registrationDataSource.updateField(item.id,[{
      fieldName:"instanceStateId",
      fieldValue:stateId
    }]);
    this.$message("Регистрация подтверждено")
  }


  async remove(item:any){
    await this.registrationDataSource.remove(item.id);
    this.$message("Успешно удалено")
  }

  registrationDataSource = new ListDataSource({
    className:"EventRegistration",
    config:{
      filter:JSON.stringify([
        {
          fieldName: "eventId",
          fieldValue: +this.$route.params.id
        }
      ])
    }
  })

  shortProperties = [
    "scheduleHtml",
    "eventPersons",
    "dateStart",
    "dateEnd",
    "countryId",
    "cityId",
    "address",
    "priceText",
    "fullInfo",
    "layout",
    "contact1",
    "contact2",
    "phone1",
    "phone2",
    "email",
    "footerText",
  ]

  usedProperties = [
    "placements",
    "caption",
    "description",
      "eventMode"
  ]

  properties = [
    {
      "name": "placements",
      "type": "string",
      "description": null,
      "caption": "Количество мест",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "eventMode",
      "type": "enum",
      "description": null,
      "caption": "Режим события",
      "editor": "enum",
      "priority": 0,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "items": [
        {
          "id": 0,
          "caption": "По умолчанию"
        },
        {
          "id": 1,
          "caption": "Трансляция"
        }
      ],
      "config": {
        "mode": "menu"
      }
    },
    {
      "name": "dateStart",
      "type": "string",
      "description": null,
      "caption": "Начало",
      "editor": "datetime",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {
        "mask": "##.##.#### ##:##",
        "dateFormat": "DD.MM.YYYY HH:mm"
      }
    },
    {
      "name": "dateEnd",
      "type": "string",
      "description": null,
      "caption": "Конец",
      "editor": "datetime",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {
        "dateFormat": "DD.MM.YYYY HH:mm",
        "mask": "##.##.#### ##:##"
      }
    },
    {
      "name": "registrationDateStart",
      "type": "string",
      "description": null,
      "caption": "Начало регистрации",
      "editor": "datetime",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "registrationDateEnd",
      "type": "string",
      "description": null,
      "caption": "Конец регистрации",
      "editor": "datetime",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "type",
      "type": "enum",
      "description": null,
      "caption": "Тип события",
      "editor": "enum",
      "priority": 0,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "items": [
        {
          "id": 0,
          "caption": "Вебинар"
        },
        {
          "id": 1,
          "caption": "Лекционный курс"
        },
        {
          "id": 2,
          "caption": "Лекционно-демонстрационный курс"
        },
        {
          "id": 3,
          "caption": "Лекционно-практический курс"
        },
        {
          "id": 4,
          "caption": "Конгресс"
        }
      ],
      "config": {
        "mode": "menu"
      }
    },
    {
      "name": "countryId",
      "type": "string",
      "description": null,
      "caption": "Страна",
      "editor": "countryselector",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "cityId",
      "type": "string",
      "description": null,
      "caption": "Город",
      "editor": "cityselector",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "address",
      "type": "string",
      "description": null,
      "caption": "Адрес",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "accreditation",
      "type": "string",
      "description": null,
      "caption": "Аккредитация",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "priceText",
      "type": "string",
      "description": null,
      "caption": "Текст цены",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "dateTime",
      "type": "string",
      "description": null,
      "caption": "Дата проведения",
      "editor": "datetime",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "educationScore",
      "type": "string",
      "description": null,
      "caption": "Баллы НМО",
      "editor": "number",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "scheduleHtml",
      "type": "string",
      "description": null,
      "caption": "Расписание",
      "editor": "html",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "useHtmlSchedule",
      "type": "string",
      "description": null,
      "caption": "Использовать свободную форму расписания",
      "editor": "boolean",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "eventPersons",
      "type": "objectlist",
      "description": null,
      "caption": "Спикеры",
      "editor": "linkedperson",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {
        "linkedClassName": "EventPerson",
        "fieldName": "eventPersons",
        "sourceObjectFieldName": "eventId"
      }
    },
    {
      "name": "eventFolders",
      "type": "objectlist",
      "description": "Категория статьи",
      "caption": "Категория",
      "editor": "resourcefolder",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {
        "className": "EventFolder"
      }
    },
    {
      "name": "linkedCommodities",
      "type": "objectlist",
      "description": null,
      "caption": "Связанные товары",
      "editor": "linkedcommodity",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {
        "className": "Commodity"
      }
    },
    {
      "name": "name",
      "type": "string",
      "description": "Строковое уникальное имя компонента, которое используется как ЧПУ при адресации. Уникальность обеспечивается в рамках одного класса компонентов. Например компоненты класса \"Статья\" и \"Страница\" могут иметь одинаковый ЧПУ, но двух статей с одинаковыми ЧПУ быть не должно. ",
      "caption": "Системное имя",
      "editor": "systemname",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [
        {
          "validator": "max",
          "value": 150,
          "errorMessage": "Поле не может содержать больше чем 150 символов"
        }
      ],
      "config": {}
    },
    {
      "name": "caption",
      "type": "string",
      "description": "Заголовок компонента. Обычно используется как основной заголовок при визуализации как страницы, так и виджетов.",
      "caption": "Подпись",
      "editor": "string",
      "priority": 3,
      "group": "general",
      "visibleInTable": true,
      "validations": [
        {
          "validator": "max",
          "value": 250,
          "errorMessage": "Поле не может содержать больше чем 250 символов"
        },
        {
          "validator": "required",
          "value": true,
          "errorMessage": "Поле не может быть пустым"
        }
      ],
      "config": {}
    },
    {
      "name": "description",
      "type": "string",
      "description": "Короткое описание компонента, которое обычно используется для визуализации описательной части в виджетах, метаданных. Иногда используется в качестве описательного блока под заголовком в основной визуализации.",
      "caption": "Краткое описание",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "publicationDate",
      "type": "string",
      "description": null,
      "caption": "Дата публикации",
      "editor": "datetime",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "defaultImageId",
      "type": "image",
      "description": "Изображения компонента",
      "caption": "Изображения",
      "editor": "image",
      "priority": 0,
      "group": "images",
      "visibleInTable": false,
      "validations": [],
      "config": {
        "fieldName": "defaultImageId"
      }
    },
    {
      "name": "metaTitle",
      "type": "string",
      "description": null,
      "caption": "Мета заголвок",
      "editor": "string",
      "priority": 100,
      "group": "seo",
      "visibleInTable": false,
      "validations": [
        {
          "validator": "max",
          "value": 250,
          "errorMessage": "Поле не может содержать больше чем 250 символов"
        }
      ],
      "config": {}
    },
    {
      "name": "metaDescription",
      "type": "string",
      "description": null,
      "caption": "Мета описание",
      "editor": "string",
      "priority": 100,
      "group": "seo",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "h1",
      "type": "string",
      "description": null,
      "caption": "Заголовок H1",
      "editor": "string",
      "priority": 100,
      "group": "seo",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "ratings",
      "type": "string",
      "description": null,
      "caption": "Оценки",
      "editor": "ratings",
      "priority": 100,
      "group": "rating",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "likes",
      "type": "string",
      "description": null,
      "caption": "Лайки и дизлайки",
      "editor": "likes",
      "priority": 100,
      "group": "rating",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "minRating",
      "type": "number",
      "description": null,
      "caption": "Минимальный рейтинг",
      "editor": "number",
      "priority": 0,
      "group": "rating",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "maxRating",
      "type": "number",
      "description": null,
      "caption": "Максимальный рейтинг",
      "editor": "number",
      "priority": 0,
      "group": "rating",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "aggregateRating",
      "type": "number",
      "description": null,
      "caption": "Средний рейтинг",
      "editor": "number",
      "priority": 0,
      "group": "rating",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "reviews",
      "type": "string",
      "description": null,
      "caption": "Отзывы",
      "editor": "linkedreview",
      "priority": 100,
      "group": "rating",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "cssClass",
      "type": "string",
      "description": null,
      "caption": "Css класс",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [
        {
          "validator": "max",
          "value": 100,
          "errorMessage": "Поле не может содержать больше чем 100 символов"
        }
      ],
      "config": {}
    },
    {
      "name": "icon",
      "type": "string",
      "description": null,
      "caption": "Иконка",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [
        {
          "validator": "max",
          "value": 100,
          "errorMessage": "Поле не может содержать больше чем 100 символов"
        }
      ],
      "config": {}
    },
    {
      "name": "exportId",
      "type": "string",
      "description": "Внешний ID.",
      "caption": "Внешний ID",
      "editor": "string",
      "priority": 20,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "fullInfo",
      "type": "string",
      "description": "Полное описание компонента в формате Html",
      "caption": "Полное описание",
      "editor": "html",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "linkedFiles",
      "type": "file",
      "description": null,
      "caption": "Связанные файлы",
      "editor": "fileuploader",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "items": [
        {
          "type": "txt"
        },
        {
          "type": "zip"
        },
        {
          "type": "pptx"
        },
        {
          "type": "doc"
        },
        {
          "type": "docx"
        },
        {
          "type": "pdf"
        },
        {
          "type": "jpeg"
        },
        {
          "type": "jpg"
        },
        {
          "type": "png"
        }
      ]
    },
    {
      "name": "features",
      "type": "string",
      "description": null,
      "caption": "Особенности",
      "editor": "linkedfeatures",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "geoObjects",
      "type": "string",
      "description": null,
      "caption": "Гео обьекты",
      "editor": "linkedgeoobjects",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "parameters",
      "type": "string",
      "description": null,
      "caption": "Параметры",
      "editor": "parameters",
      "priority": 100,
      "group": "settings",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "authors",
      "type": "string",
      "description": null,
      "caption": "Авторы",
      "editor": "linkedauthors",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "linkedProfessionalArea",
      "type": "string",
      "description": null,
      "caption": "Профессиональные области",
      "editor": "linkedprofessionalarea",
      "priority": 100,
      "group": "linkedcomponents",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "id",
      "type": "string",
      "description": "Уникальный идентификатор компонента.",
      "caption": "ID",
      "editor": "readonly",
      "priority": 0,
      "group": "general",
      "visibleInTable": true,
      "validations": [],
      "config": {}
    },
    {
      "name": "createDate",
      "type": "date",
      "description": "Дата создания компонента",
      "caption": "Дата создания",
      "editor": "readonly",
      "priority": 1,
      "group": "general",
      "visibleInTable": true,
      "validations": [],
      "config": {}
    },
    {
      "name": "lastUpdate",
      "type": "date",
      "description": "Дата последнего обновления",
      "caption": "Дата последнего обновления",
      "editor": "readonly",
      "priority": 200,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "priority",
      "type": "number",
      "description": "Значение данного поля используется при сортировке группы однородных компонентов, если явно указано, что сортировка осуществляется по этому полю.",
      "caption": "Приоритет",
      "editor": "string",
      "priority": 100,
      "group": "general",
      "visibleInTable": false,
      "validations": [],
      "config": {}
    },
    {
      "name": "instanceStateId",
      "type": "enum",
      "description": null,
      "caption": "Статус",
      "editor": "enum",
      "priority": 100,
      "group": "visible",
      "visibleInTable": true,
      "validations": [],
      "items": [
        {
          "id": 10,
          "caption": "Удален"
        },
        {
          "id": 5,
          "caption": "Архив"
        },
        {
          "id": 1,
          "caption": "Создан"
        },
        {
          "id": 2,
          "caption": "На подтверждение"
        },
        {
          "id": 4,
          "caption": "Активен"
        },
        {
          "id": 11,
          "caption": "Выполнен"
        }
      ],
      "config": {}
    },
    {
      "name": "layout",
      "type": "enum",
      "description": null,
      "caption": "Шаблон",
      "editor": "enum",
      "priority": 100,
        mode:"menu",
      "group": "visible",
      "visibleInTable": true,
      "validations": [],
      "items": [
        {
          "id": "ComponentTemplates/DefaultEventTemplate",
          "caption": "По умолчанию"
        },
        {
          "id": "ComponentTemplates/EventTemplate01",
          "caption": "Шаблон №1"
        },
        {
          "id": "ComponentTemplates/EventTemplate02",
          "caption": "Шаблон №2"
        },
      ],
      "config": {}
    },
    {
      "name": "addressInstruction",
      "caption": "Как проехать",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    },
    {
      "name": "usedCommodities",
      "caption": "Используеммые продукты",
      "description": null,
      "editor": "html",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    },
    {
      "name": "contact1",
      "caption": "Контакт 1",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    },
    {
      "name": "contact2",
      "caption": "Контакт 2",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    },
    {
      "name": "contact2",
      "caption": "Контакт 2",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    },
    {
      "name": "phone1",
      "caption": "Телефон 1",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "isExtended": true,
      "defaultValue": null,
      "config": {
        "mask": "+7 (###) ###-##-##"
      }
    },
    {
      "name": "phone2",
      "caption": "Телефон 2",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "isExtended": true,
      "defaultValue": null,
      "config": {
        "mask": "+7 (###) ###-##-##"
      }
    },
    {
      "name": "email",
      "caption": "Почта",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    },
    {
      "name": "footerText",
      "caption": "Текст в подвале",
      "description": null,
      "editor": "string",
      "group": "general",
      "priority": null,
      "config": null,
      "isExtended": true,
      "defaultValue": null
    }
  ]


  async mounted(){
    await this.registrationDataSource.get();
    if(this.canSubscribe){
      const {data} = await axios.get(`/manage/event/${this.dataSource.id}/subscribe/${userModule.profile.id}/check/`)
      this.subscribtion = data;
    }
    this.loaded = true;
  }


}
