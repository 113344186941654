
import Vue from "vue";
import DefaultContainer from "./containers/default.vue";
import DefaultProvider from "./providers/default.vue";
import ObjectProvider from "./providers/object.vue";
import AceModalEditor from "./editors/ace-modal.vue";
import EnumEditor from "./editors/enum.vue";
import BooleanEditor from "./editors/bool.vue";
import NumberEditor from "./editors/number.vue";
import StringEditor from "./editors/string.vue";
import { checkToken } from "@/cms-services/token";

export default Vue.extend({
  name: "app-form",
  components: {
    AceModalEditor,
    StringEditor,
    NumberEditor,
    DefaultProvider,
    DefaultContainer,
    ObjectProvider,
    BooleanEditor,
    EnumEditor,
  },
  props: {
    fields: Array,
    model: Object,
    actions: Object,
    customValidators: Object,
    editMode: {
      type: Boolean,
      default: true,
    },
    nested: Array,
    container: Object,
  },
  data: () => ({
    config: {},
  }),
  created() {
    checkToken();
  },
  computed: {
    containerName(): string {
      const container = (this as any).container?.name ?? "default";
      return container + "-container";
    },
  },
  methods: {
    async validate() {
      const providers = (this as any).$refs.providers.$children;
      let valid = true;
      for (const provider of providers) {
        const _valid = await provider.validate();
        if (!_valid) valid = false;
      }
      return valid;
    },
  },
});
