
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ObjectExpansionEditor extends Vue {
  @Prop() dataSource!: ObjectDataSource;
  @Prop() propertiesOverrideInfo: any;
  @Prop() propertyGroupsOverrideInfo: any;

  get groups(): Array<any> {
    return this.dataSource?.metadata?.groups ?? [];
  }

  filterProperties(group) {
    const properties = this.dataSource?.metadata?.properties;
    return !(properties?.length > 0)
      ? []
      : properties.filter((p: any) => p.group === group.name);
  }

  created() {
    this.overrideProperties();
    this.overridePropertyGroups();
  }

  overrideProperties() {
    if (!this.propertiesOverrideInfo) return;
    const properties = this.dataSource?.metadata?.properties;
    for (const property of properties) {
      for (const propertyOverrideInfo of this.propertiesOverrideInfo) {
        if (property.name != propertyOverrideInfo.name) continue;
        Object.assign(property, propertyOverrideInfo);
      }
    }
  }
  overridePropertyGroups() {
    if (!this.propertyGroupsOverrideInfo) return;
    const groups = this.dataSource?.metadata?.groups;
    for (const group of groups) {
      for (const groupOverrideInfo of this.propertyGroupsOverrideInfo) {
        if (group.name != groupOverrideInfo.name) continue;
        Object.assign(group, groupOverrideInfo);
      }
    }
  }
}
