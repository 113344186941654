export const initStatisticRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/statistics",
          name: "statistic",
          component: () => import("@/pages/manage/statistics/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors, config.roles.invitationSupervisor],
          },
        },
        {
          path: "/manage/statistics/:id",
          name: "statistic-id",
          component: () => import("@/pages/manage/statistics/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors, config.roles.invitationSupervisor],
          },
        },
      ];
