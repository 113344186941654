export const initSecurityGroupRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/security-group",
          name: "security-group",
          component: () => import("@/pages/manage/security-group/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },

        {
          path: "/manage/security-group/:id",
          name: "security-group-id",
          component: () => import("@/pages/manage/security-group/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
