import { getGUID } from "@/cms-services/helpers";
import { validate } from "@/cms-services/validators";
import Vue from "vue";

const editor = Vue.extend({
  props: {
    field: Object,
    model: Object,
    actions: Array,
    customValidators: Object,
  },
  data: () => ({
    attrs: {},
    validations: [],
    watchers: null,
    config: {},
    inputId: null,
  }),
  computed: {
    showErrors(): Boolean {
      return (this as any).field?.errorMessages?.length > 0;
    },
    value: {
      get() {
        const regex = new RegExp(/[\.]/gm);

        if (!regex.test((this as any).field.name)) {
          return (this as any).model[(this as any).field.name];
        }
        let schema = this.model;

        const pList = this.field.name.split(".");
        const len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {};
          schema = schema[elem];
        }
        return schema[pList[len - 1]];
      },
      set(value): void {
        const regex = new RegExp(/[\.]/gm);
        if (!regex.test((this as any).field.name)) {
          (this as any).model[(this as any).field.name] = value;
        }
        let schema = (this as any).model;

        const pList = (this as any).field.name.split(".");
        const len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {};
          schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
      },
    },
  },
  watch: {
    model: {
      deep: true,
      async handler() {
        (this as any).onModelChanged();
      },
    },
  },
  created() {
    const self: any = this;
    self.inputId = getGUID();
    if (!self.field) throw new Error("Настройки поля не переданы");
    self.attrs = self.field?.attrs;
    self.validations = self.field?.validations;
    self.watchers = self.field?.watchers;
    self.config = self.field?.config;
    self.onModelChanged();
  },
  methods: {
    async validate(): Promise<boolean> {
      const self: any = this;
      if (!self.field.validations) return true;

      const _res: any = await validate(
        self.field,
        self.model,
        self.customValidators,
      );
      self.field.errorMessages = _res.errorMessages;
      self.field.successMessages = _res.successMessages;
      return self.field.errorMessages.length === 0;
    },
    async onModelChanged() {
      const self: any = this;
      if (!(self.watchers?.length > 0)) return;
      for (const watcher of self.watchers) {
        const { changeModel, changeAttrs, changeValidations } = watcher;
        if (!!changeModel) await self.actions[changeModel]();
        if (!!changeAttrs)
          self.attrs = await self.actions[changeAttrs](self.attrs);
      }
    },
  },
});

export default editor;
