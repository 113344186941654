
import { mdiClose } from '@mdi/js';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UploadFileDialog extends Vue {
  @Prop({ default: 700 }) maxWidth!: number;
  @Prop() value!: boolean;
  @Prop({ default: 'Загрузить файлы' }) title!: string;
  @Prop({ default: () => [] }) fileTypes!: string[];
  @Prop({ default: 0 }) maxFiles!: number;
  @Prop({ default: 'Перетащите сюда файлы или ' }) placeholder!: string;
  @Prop({ default: 'выберите...' }) placeholderBtnText!: string;
  @Prop() loading!: boolean;

  icons: any = {
    close: mdiClose,
  };
}
