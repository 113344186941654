
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import _ from "lodash";
import { remove } from "@/cms-services/helpers";
import { Filter } from "@/cms-services/filter";

@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class ProductLinkInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  searchTimer: any = null;
  dialog: boolean = false;
  searchText: string = "";
  filterText: string = "";
  loaded: boolean = false;
  itemsCopy: any = [];
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  featureDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "feature",
  });

  linkedFeatureDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "feature",
  });
  componentFeatureDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "componentFeature",
  });

  $message: any;

  updateFeatures() {
    let sourceItems = Array.from([...this.featureDataSource.items]);

    for (const feature of sourceItems) {
      const exists = this.dataSource.model?.features.some(
        (c: any) => c.featureId == feature.id,
      );
      if (exists) {
        remove(this.featureDataSource.items, (c: any) => c.id == feature.id);
      }
    }
    this.linkedFeatureDataSource.items = this.dataSource.model?.features?.map(
      (cf: any) => this.itemsCopy.find((f: any) => f.id == cf.featureId),
    );
  }

  filterLinkedfeature() {
    this.updateFeatures();
    if (!this.filterText) return;
    const re = new RegExp(this.filterText, "gi");
    this.linkedFeatureDataSource.items =
      this.linkedFeatureDataSource.items.filter((c: any) =>
        c.caption.match(re),
      );
  }

  async created() {
    await this.featureDataSource.get();
    this.itemsCopy = _.cloneDeep(this.featureDataSource.items);
    this.linkedFeatureDataSource.metadata = this.featureDataSource.metadata;
    this.linkedFeatureDataSource.total =
      this.dataSource.model?.features?.length;
    this.componentFeatureDataSource.items = this.dataSource.model?.features;
    this.updateFeatures();
    this.loaded = true;
  }

  async add(item: any) {
    await this.componentFeatureDataSource.add({
      componentId: this.dataSource.id,
      featureId: item.id,
    });

    await this.dataSource.get();
    this.updateFeatures();
    this.linkedFeatureDataSource.total =
      this.linkedFeatureDataSource.items.length;
    this.$message("Успешно добавлено");
  }

  async remove(item: any) {
    const cf = this.dataSource.model?.features?.find(
      (c: any) => c.featureId == item.id,
    );
    await this.componentFeatureDataSource.remove(cf.id);
    remove(this.linkedFeatureDataSource.items, (c: any) => c.id === item.id);
    this.featureDataSource.items.push(item);
    this.linkedFeatureDataSource.total =
      this.linkedFeatureDataSource.items.length;

    this.$message("Успешно удалено");
  }

  @Watch("searchText")
  debounceSearch(value: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      this.featureDataSource.config.filter = JSON.stringify([
        new Filter("searchText", value),
      ]);
      await this.featureDataSource.get();
      this.updateFeatures();
    }, 1000);
  }
}
