
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Watch } from "vue-property-decorator";
import ObjectDataTable from "@/components/helpers/object-data-table.vue";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiMinus,
  mdiPen,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import editor from "./editor";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import _ from "lodash";
import { remove } from "@/cms-services/helpers";
import { Filter } from "@/cms-services/filter";

@Component({
  mixins: [editor],
  components: { ObjectDataTable },
})
export default class ProductLinkInlineEditor extends Vue {
  dataSource!: ObjectDataSource;
  searchTimer: any = null;
  dialog: boolean = false;
  searchText: string = "";
  filterText: string = "";
  loaded: boolean = false;
  itemsCopy: any = [];
  icons: any = {
    plus: mdiPlus,
    minus: mdiMinus,
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };
  geoObjectClassName: any = null;

  headers: any[] = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "caption",
      sortable: true,
    },
    {
      text: "Тип",
      value: "className",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  geoObjectTypes: any[] = [
    {
      caption: "Станция",
      value: "station",
    },
    {
      caption: "Районы",
      value: "district",
    },
    {
      caption: "Филиал Клиники",
      value: "clinicbranch",
    },
    {
      caption: "Филиал Компании",
      value: "companybranch",
    },
  ];

  geoObjectDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "geoObject",
  });

  linkedGeoObjectDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "geoObject",
  });
  componentgeoObjectDataSource: ListDataSource = new ListDataSource({
    config: { pageIndex: 1, pageSize: 1000 },
    className: "componentgeoObject",
  });

  getClassCaption(className) {
    switch (className) {
      case "District":
        return "Район";
      case "ClinicBranch":
        return "Филлиал клиники";
      case "Station":
        return "Станция";
    }
  }

  $message: any;

  updategeoObjects() {
    let sourceItems = Array.from([...this.geoObjectDataSource.items]);

    for (const geoObject of sourceItems) {
      const exists = this.dataSource.model?.geoObjects.some(
        (c: any) => c.geoObjectId == geoObject.id,
      );
      if (exists) {
        remove(
          this.geoObjectDataSource.items,
          (c: any) => c.id == geoObject.id,
        );
      }
    }
    this.linkedGeoObjectDataSource.items =
      this.dataSource.model?.geoObjects?.map((cf: any) =>
        this.itemsCopy.find((f: any) => f.id == cf.geoObjectId),
      );
  }

  async refreshItems(className: string) {
    this.geoObjectDataSource.className = className;
    await this.geoObjectDataSource.get();
    this.updategeoObjects();
  }

  filterLinkedgeoObject() {
    this.updategeoObjects();
    if (!this.filterText) return;
    const re = new RegExp(this.filterText, "gi");
    this.linkedGeoObjectDataSource.items =
      this.linkedGeoObjectDataSource.items.filter((c: any) =>
        c.caption.match(re),
      );
  }

  async created() {
    await this.geoObjectDataSource.get();
    this.itemsCopy = _.cloneDeep(this.geoObjectDataSource.items);
    this.linkedGeoObjectDataSource.metadata = this.geoObjectDataSource.metadata;
    this.linkedGeoObjectDataSource.total =
      this.dataSource.model?.geoObjects?.length;
    this.componentgeoObjectDataSource.items = this.dataSource.model?.geoObjects;
    this.updategeoObjects();
    this.loaded = true;
  }

  async add(item: any) {
    await this.componentgeoObjectDataSource.add({
      componentId: this.dataSource.id,
      geoObjectId: item.id,
    });

    await this.dataSource.get();
    this.updategeoObjects();
    this.linkedGeoObjectDataSource.total =
      this.linkedGeoObjectDataSource.items.length;
    this.$message("Успешно добавлено");
  }

  async remove(item: any) {
    const cf = this.dataSource.model?.geoObjects?.find(
      (c: any) => c.geoObjectId == item.id,
    );
    await this.componentgeoObjectDataSource.remove(cf.id);
    remove(this.linkedGeoObjectDataSource.items, (c: any) => c.id === item.id);
    this.geoObjectDataSource.items.push(item);
    this.linkedGeoObjectDataSource.total =
      this.linkedGeoObjectDataSource.items.length;

    this.$message("Успешно удалено");
  }

  @Watch("searchText")
  debounceSearch(value: string) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(async () => {
      this.geoObjectDataSource.config.filter = JSON.stringify([
        new Filter("searchText", value),
      ]);
      await this.geoObjectDataSource.get();
      this.updategeoObjects();
    }, 1000);
  }
}
