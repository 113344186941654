
import Editor from "./editor";
export default Editor.extend({
  computed: {
    min(): any {
      return (this as any).attrs?.min;
    },
    max(): any {
      return (this as any).attrs?.max;
    },
  },
});
