export const initResumeRoutes = (config: any, enabled: boolean = true) =>
    !enabled
    ? []
    : [
        {
          path: "/manage/doctors/:id/resume/",
          name: "resume",
          component: () => import("@/pages/manage/doctors/zones/resumes/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/doctors/:id/zones/resume/:resumeId",
          name: "resume-id",
          component: () => import("@/pages/manage/doctors/zones/resumes/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
