
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import FilePreviews from "@/mixins/file-types/index";
import { getFilePreview } from "@/cms-services/helpers";
@Component({
  mixins: [FilePreviews],
})
export default class FileUploader extends Vue {
  @Ref("fileRef") readonly fileRef!: HTMLInputElement;
  @Prop({ default: () => [] }) fileTypes!: string[];
  @Prop({ default: 0 }) maxFiles!: number;
  @Prop({ default: "Перетащите сюда изображения или " }) placeholder!: string;
  @Prop({ default: "выберите..." }) placeholderBtnText!: string;
  @Prop({ default: true }) showTable!: boolean;
  @Prop({ default: true }) showProgress!: boolean;

  dragEnter = false;
  toRenderFiles: any = [];
  loadedResolve!: Function;
  loadedRreject!: Function;
  $message: any;

  change({ target }: any) {
    console.log(target)
    this.readFiles(target);
  }

  onDrop({ dataTransfer }: any) {
    this.dragEnter = false;
    this.readFiles(dataTransfer);
  }

  async readFiles({ files }: any) {
    const _result = this.validateFiles(files);
    if (_result !== "") {
      this.$message(_result, "error");
      return;
    }

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      this.readFile(file, files?.length);
      this.$emit("upload", {
        data: formData,
        file,
        onProgress: this.progressOnLoad,
      });
      this.fileRef.value = "";
    }
  }

  progressOnLoad(e: any, file: File) {
    const loadingProgress = Math.round((e.loaded / e.total) * 100).toString();
    const index = this.toRenderFiles.findIndex((f: any) => f.name == file.name);
    this.toRenderFiles[index].loadingState = parseInt(loadingProgress);
    if (this.toRenderFiles[index].loadingState === 100) {
      this.toRenderFiles.splice(index, 1);
    }
  }

  readFile(file: any, fileCount: number) {
    const _reader = new FileReader();
    _reader.onload = ({ target }: any) => {
      this.toRenderFiles.push({
        name: file.name,
        size: file.size,
        preview: this.getFilePreview(file, target?.result),
        loadingState: 0,
      });

      if (this.toRenderFiles?.length === fileCount) {
        this.$emit("finish");
      }
    };
    _reader.readAsDataURL(file);
  }

  validateFiles(files: any) {
    if (!files || files.length === 0) return "Загрузите файлы";
    const fileCount = this.toRenderFiles.length + files.length > this.maxFiles;

    if (this.maxFiles !== 0 && fileCount) {
      this.fileRef.value = "";
      return `Вы не можете загрузить больше чем ${this.maxFiles} файлов`;
    }

    if (!(this.fileTypes?.length > 0)) {
      return "";
    }

    for (const file of files) {
      const _isCorrect = this.validateFileType(file);
      if (!_isCorrect) return "Загрузите правельный тип файла";
    }

    return "";
  }

  validateFileType({ name }: any) {
    let _isCorrect = false;
    for (const fileType of this.fileTypes) {
      const _fileExtention = name
        .substring(name.lastIndexOf(".") + 1)
        .toLowerCase();

      if (_fileExtention === fileType.toLowerCase()) {
        _isCorrect = true;
      }
    }

    return _isCorrect;
  }

  getFilePreview = (file: File, preview: any) => {
    const isImg = file.type.split("/")[0] === "image";
    return {
      isImg,
      img: isImg ? preview : getFilePreview(file.name),
    };
  };
}
