
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { directive as onClickaway } from "vue-clickaway";
import ContentBlockImageSelector from "./image-selector/index.vue"
import { BTooltip } from "bootstrap-vue";
import ContentBlockType from "./type.vue";
import {
  BDropdown,
  BDropdownItem,
  BButton,
  BIconGear,
  BIconPencil,
  BIconFiles,
  BIconTrash,
  BIconArrowsMove,
  BIconEye,
  BIconEyeSlash,
  BIconPlus,
  BIconUpload,
} from "bootstrap-vue";
import { contentBlockModule } from "@/bootstrap/store/contentBlock";
import ContentBlockFileUploader from "@/bootstrap/components/content/block/file/uploader.vue";
import ContentBlockFileSelector from "@/bootstrap/components/content/block/file/selector.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
import { ContentBlockFileDataSource } from "@/data/List/ContentBlockFileDataSource";
import ContentBlockDocumentSelector from "@/bootstrap/components/content/block/document-selector/index.vue"
@Component({
  name: "content-block-controller",
  components: {
    BTooltip,
    BDropdown,
    BDropdownItem,
    BButton,
    BIconGear,
    BIconPencil,
    BIconFiles,
    BIconTrash,
    BIconArrowsMove,
    BIconEye,
    BIconEyeSlash,
    BIconPlus,
    BIconUpload,
    ContentBlockType,
    ContentBlockFileUploader,
    ContentBlockFileSelector,
    ContentBlockImageSelector,
    ContentBlockDocumentSelector
  },
  directives: {
    onClickaway: onClickaway,
  },
})
export default class ContentBlockController extends Vue {
  @Prop() block: any;
  @Prop() preview!: boolean;
  @Prop() canEdit!: boolean;
  @Prop({ default: true }) allowMove!: boolean;
  @Prop({ default: true }) allowType!: boolean;
  @Prop({ default: true }) allowEdit!: boolean;
  @Prop() dataSource!: ListDataSource;
  @Ref("fileSelector") fileSelector!: ContentBlockImageSelector;
  @Ref("documentSelector") documentSelector!: ContentBlockDocumentSelector;

  previewMode: boolean = false;
  editMode: boolean = false;

  fileDataSource: ContentBlockFileDataSource | null = null;
  loaded: boolean = false;

  get isPreview() {
    return this.preview || this.previewMode;
  }

  get isVisible() {
    return this.block?.instanceStateId == 4;
  }

  get blockTypes() {
    return this.$store.getters[contentBlockModule.getters.blocks].find(
      (g: any) => g.group === this.block.group,
    ).blocks;
  }

  closeEditMode() {
    this.editMode = false;
  }
  async changeState() {
    await this.dataSource.updateField(this.block.id, [
      {
        fieldName: "instanceStateId",
        fieldValue: this.block?.instanceStateId == 4 ? 2 : 4,
      },
    ]);
    (this as any).$alert(`Данные успешно сохранены`);
  }

  async created() {
    if (this.block.id != undefined) {
      this.fileDataSource = new ContentBlockFileDataSource(this.block.id, {
        filter: JSON.stringify([
          {
            fieldName: "contentBlockId",
            fieldValue: this.block.id,
          },
        ]),
      });

      await this.fileDataSource.get();
    }

    this.loaded = true;
  }

  configurate(): void {
    if (this.$listeners?.configurate) {
      this.$emit("configurate", {
        mutationName: contentBlockModule.mutations.openBlockConfigurator,
        payload: {
          block: this.block,
          dataSource: this.dataSource,
        },
      });
      return;
    }
    this.$store.commit(contentBlockModule.mutations.openBlockConfigurator, {
      block: this.block,
      dataSource: this.dataSource,
    });
  }
  async copy() {
    const index = this.dataSource.items.findIndex(
      (b: any) => +b.id === +this.block.id,
    );
    if (index == -1) throw new Error("Элемент не найден");
    const obj = {};
    [
      "name",
      "group",
      "caption",
      "description",
      "parentId",
      "model",
      "contentId",
      "config",
      "priority",
      "instanceStateId",
    ].forEach((key) => (obj[key] = this.block[key]));
    await this.dataSource.add(obj, true, index);
    (this as any).$alert(`Блок успешно скопирован`);
  }
  chooseBlock(addToEnd: boolean) {
    const index = this.dataSource.items.findIndex(
      (b: any) => +b.id === +this.block.id,
    );
    this.$store.commit(contentBlockModule.mutations.openBlockPallete, {
      dataSource: this.dataSource,
      addToEnd,
      insertIndex: index,
    });
  }



  async changeType(newBlock) {
    this.block.name = newBlock.name;
    this.block.description = newBlock.description;
    this.block.caption = newBlock.caption;
    Object.assign(newBlock.model, this.block.model);
    this.block.config = newBlock.config;
    await this.dataSource.update(this.block);
    (this as any).$alert(`Блок успешно обновлён`);
  }
  async remove() {
    const confirm = await (this as any).$getConfirm(
      "Вы действительно хотите удалить данный блок со всеми его содержимыми??",
    );

    if (!confirm) return;
    await this.dataSource.remove(this.block.id);
    (this as any).$alert(`Блок успешно удалён`);
  }
  editModel(): void {
    this.$store.commit(contentBlockModule.mutations.openModelEditor, {
      block: this.block,
      dataSource: this.dataSource,
    });
  }
  async selectFile() {
    return await this.fileSelector.select();
  }

  async selectDocument(existFiles:any[]){
    return await this.documentSelector.open(existFiles);
  }
}
