
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

import DefaultContainer from "./containers/default.vue";
import GridContainer from "./containers/grid.vue";
import DefaultProvider from "./providers/default.vue";
import ObjectProvider from "./providers/object.vue";

import StringEditor from "./editors/string.vue";
import SystemnameEditor from "./editors/systemName.vue";
import NumberEditor from "./editors/number.vue";
import BoolEditor from "./editors/bool.vue";
import EnumEditor from "./editors/enum.vue";
import PasswordEditor from "./editors/password.vue";
import TitleEditor from "./editors/title.vue";
import MemoEditor from "./editors/memo.vue";
import FileUploaderEditor from "./editors/file-uploader.vue";
import DateEditor from "./editors/date.vue";
import AutoCompleteSelectEditor from "./editors/auto-complete-selector.vue";
import ColorSelectorEditor from "./editors/color-selector.vue";
import AceEditor from "./editors/ace.vue";

@Component({
  name: "app-form",
  components: {
    StringEditor,
    NumberEditor,
    BoolEditor,
    PasswordEditor,
    TitleEditor,
    DefaultProvider,
    DefaultContainer,
    GridContainer,
    ObjectProvider,
    EnumEditor,
    SystemnameEditor,
    MemoEditor,
    FileUploaderEditor,
    DateEditor,
    AutoCompleteSelectEditor,
    ColorSelectorEditor,
    AceEditor,
  },
})
export default class AppForm extends Vue {
  @Ref("providers") providers!: any;
  @Prop() fields!: Array<any>;
  @Prop() model!: any;
  @Prop() actions!: any;
  @Prop() customValidators!: any;
  @Prop({ default: true }) editMode!: boolean;
  @Prop() nested!: any;
  @Prop() container!: any;

  config: any = {
    fields: this.fields,
    model: this.model,
    actions: this.actions,
    customValidators: this.customValidators,
    editMode: this.editMode,
    nested: this.nested,
    attrs: this.container?.attrs ?? {},
  };

  get containerName(): string {
    const container = this.container?.name ?? "default";
    return container + "-container";
  }

  public async validate() {
    let valid = true;
    for (const provider of this.providers.$children) {
      if (!provider.canShow) continue;
      const _valid = await provider.validate();
      if (!_valid) valid = false;
    }
    return valid;
  }

  public reset(): void {
    for (const provider of this.providers.$children) {
      provider.reset();
    }
  }
}
