
import {Vue, Component} from 'vue-property-decorator';
import {mdiAccount, mdiClose, mdiPlus, mdiTrashCan} from "@mdi/js";
import editor from "@/components/common/inline-editor/editor";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";

@Component({
  mixins: [editor],
})
export default class parameters extends Vue {
  visible:boolean =  false
  icons:any = {
    close: mdiClose,
    account: mdiAccount,
    plus: mdiPlus,
    trash: mdiTrashCan,
  }
  private dataSource!: ObjectDataSource;
  $message: any;

  close() {
    this.visible = false;
  }
  add() {
    this.dataSource.model.parameters.push({
      name: '',
      value: '',
    });
  }
  remove(index) {
    this.dataSource.model.parameters.splice(index, 1);
  }
  async save() {
    await this.dataSource.updateField({
      fieldName:"parameters",
      fieldValue: this.dataSource.model.parameters
    })
    this.$message("Поле успешно сохранено");
    this.visible = false;
  }


  created(){
    if(!this.dataSource.model.parameters){
      this.dataSource.model.parameters = []
    }
  }


}
