
import { getGUID } from "@/cms-services/helpers";
import { Component, Ref } from "vue-property-decorator";
import Container from "./container";

@Component
export default class DefaultContainer extends Container {
  @Ref("cols") cols!: any;
  rows: any = [];

  created() {
    if (!this.fields) throw new Error("Настройки полей не переданы");
    this.rows = this.fields.map((r: any) => ({
      ...r,
      id: getGUID(),
      cols: r.cols.map((c: any) => ({
        ...c,
        id: getGUID(),
        fields: c.fields.map((f: any) => ({
          ...f,
          id: getGUID(),
          errorMessages: [],
          successMessages: [],
        })),
      })),
    }));
  }
}
