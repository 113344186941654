
import moment from "moment-timezone";
import { Vue, Component, Watch } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class StringInlineEditor extends Vue {
  value: any;
  emit: any;
  save: any;
  localValue: any = "";
  cancelChanges: any;
  public async update() {
    if (!moment(this.localValue, "DD.MM.YYYY").isValid()) {
      this.cancelChanges();
      return;
    }
    this.value = new Date(moment(this.localValue,"DD.MM.YYYY").format("YYYY-MM-DD")).toISOString()
    await this.save();
  }

  mounted() {
    this.localValue = moment(this.value).format("DD.MM.YYYY");
  }

  dropdownOpen: boolean = false;
  meridiam: string = "AM";
  displayDate: string = "";
  dateModel: string = "";
  timeModel: string = "";
  monthNames: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  get model(): string {
    return this.value;
  }

  formatDate(date: string): string {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    const monthName = this.monthNames[parseInt(month)];
    return `${monthName} ${day}, ${year}`;
  }

  confirm(): void {
    this.onUpdateDate();
    this.dropdownOpen = false;
  }

  onUpdateDate(): void {
    if (!this.dateModel || !this.timeModel) return;

    const selectedTime = this.timeModel + " " + this.meridiam;
    this.displayDate = this.formatDate(this.dateModel) + " " + selectedTime;
    this.$emit("input", this.dateModel + " " + selectedTime);
  }
}
