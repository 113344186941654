export const initRoleRoutes = (config: any, enabled: boolean = true) =>
    !enabled
    ? []
    : [
        {
          path: "/manage/roles",
          name: "role",
          component: () => import("@/pages/manage/roles/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/roles/:id",
          name: "role-id",
          component: () => import("@/pages/manage/roles/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
