
import { formatDate } from "@/cms-services/date";
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class ReadonlyInlineEditor extends Vue {
  field: any;
  value: any;
  notDefinedCaption: any;
  public get formatValue() {
    const value = this.value ?? `[${this.notDefinedCaption}]`;
    switch (this.field?.type) {
      case "date":
        return formatDate(value, "DD.MM.YYYY HH:mm");
      case "datetime":
        return formatDate(value, "DD.MM.YYYY HH:mm");
      default:
        return value;
    }
  }
}
