
import { ContentBlock } from "@/bootstrap/components/content/block";
export default ContentBlock.extend({
  name: "content-block-text-01",
  data:()=>({
    config:{
      "textSize": "8",
      "imageSize": "4",
      "imgPosition": 1
    }
  }),
  computed: {
    breakpoint() {
      return (this as any).block?.config?.breakpoint;
    },
    animation() {
      return (this as any).block?.config?.animation;
    },
  },
  methods: {
    async selectImage(selectFile, block) {
      const file  = await selectFile();
      block.model.img = `/api/v1/objectimage/p${file.id}.jpg`;
      await this.updateModel();
      (this as any).$message("Изображение успещно обновлено");
    },
  },
  async created(){
    let hasChanges = false;
    for (const [k,v] of  Object.entries(this.config)) {
      if(!this.block.config.hasOwnProperty(k)){
        hasChanges = true;
        this.$set(this.block.config, k, v);
      }
    }

    if (hasChanges){
      await this.dataSource.updateField(this.block.id, [
        {
          fieldName: "config",
          fieldValue: this.block.config,
        },
      ]);
    }
  }
});
