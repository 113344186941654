import { ListDataSource } from "@/data/List/ListDataSource";

export const getContentBlockDataSource = (
  contentId: number,
  parentId: number | null,
) => {
  return new ListDataSource({
    className: "contentblock",
    config: {
      orderFieldName: "Priority",
      orderFieldDirection: "ASC",
      filter: JSON.stringify([
        {
          fieldName: "contentId",
          fieldValue: contentId,
        },
        {
          fieldName: "parentId",
          fieldValue: parentId,
        },
      ]),
    },
  });
};
