export const initCertificateTemplateRoutes = (
  config: any,
  enabled: boolean = true,
) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/certificate-templates",
          name: "certificatetemplate",
          component: () =>
            import("@/pages/manage/certificate-templates/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors,config.roles.eventManagers],
          },
        },
        {
          path: "/manage/certificate-templates/:id",
          name: "certificatetemplate-id",
          component: () =>
            import("@/pages/manage/certificate-templates/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors,config.roles.eventManagers],
          },
        },
      ];
