
import { Vue, Component } from "vue-property-decorator";
import Album from "./album.vue";
import {contentBlockModule} from "@/bootstrap/store/contentBlock";
@Component({
  components: {Album}
})
export default class ImageSelector extends Vue {
  selectedAlbumId: any = null;
  contentId:any = null
  dialog = false;
  resolve!:Function
  albums:any = []
  images:any = []

  updateImages(){
    this.images =  this.albums
      ?.find?.((ia:any) => ia.id == this.selectedAlbumId)
      ?.images
      ?.sort?.((p: any, n: any) => p.priority - n.priority);
  }


  public select(){
     if(this.contentId != this.$store.getters[contentBlockModule.getters.contentId]){
       this.albums = this.$store.state["content-block"].componentDataSource.model.imageAlbums
         .sort((p: any, n: any) => p.priority - n.priority)
       this.contentId = this.$store.getters[contentBlockModule.getters.contentId];
     }

    if(this.selectedAlbumId != this.albums?.[0]?.id){
      this.selectedAlbumId = this.albums?.[0]?.id;
      this.updateImages()
    }

    this.dialog = true;
    return new Promise(r => this.resolve = r);
  }

  close(image:any){
    this.resolve(image);
    this.dialog = false;
  }

}
