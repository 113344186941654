import { IBaseConfig } from "@/ioc/types";
import axios from "axios";
import { ListDataSource } from "./ListDataSource";

export class ComponentLinkDataSource extends ListDataSource {
  constructor(config: IBaseConfig = {}) {
    super({
      className: "ComponentLink",
      config,
    });
  }

  public async remove(model: any): Promise<void> {
    try {
      await axios.post(`${this.baseUrl}/delete`, model);
    } catch (error) {
      throw error;
    }
  }
}
