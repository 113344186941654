import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import { isJwtValid, tokenName } from "@/cms-services/token";
import Cookies from "js-cookie";
import store from "@/store";
import axios from "axios";
import userModule from "./user";
import appConfigModule from "../plugins/appConfig";

@Module({ store, namespaced: true, name: "authModule", dynamic: true })
class AuthModule extends VuexModule {
  public token: any = null;
  public loading: boolean = false;

  get isAuthenticated(): boolean {
    return !!this.token;
  }

  @Mutation
  private setToken(token: string | null): void {
    if (!token) throw new Error("Токен не передан");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Cookies.set(tokenName, token, { expires: 7 });
    this.token = token;
  }

  @Mutation
  public logout(): void {
    delete axios.defaults.headers.common["Authorization"];
    Cookies.remove(tokenName);
    userModule.setProfile(null);
    this.token = null;
  }

  @Action({ rawError: true })
  public async signIn(loginModel: any) {
    try {
      appConfigModule.setLoading();
      const { data } = await axios.post("/user/authenticate/", loginModel);
      this.setToken(data.token);
      userModule.setProfile(data.profile);
      userModule.setRoles(data.roles);
    } catch (e) {
      this.logout();
      throw e;
    } finally {
      appConfigModule.clearLoading();
    }
  }

  @Action({ rawError: true })
  async autoLogin() {
    const _jwtToken: string | null = Cookies.get(tokenName);
    if (!isJwtValid(_jwtToken)) {
      this.logout();
      return false;
    }

    this.setToken(_jwtToken);
    await userModule.getUser();
    return true;
  }
}
const authModule = getModule(AuthModule);

export default authModule;
