export const initCrmRoutes = (config: any, enabled: boolean = true) =>
  !enabled
    ? []
    : [
        {
          path: "/manage/crm/phonecalls",
          name: "crm-phone-calls",
          component: () => import("@/pages/manage/crm/phonecalls/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/crm/clientmanagers",
          name: "crm-client-managers",
          component: () =>
            import("@/pages/manage/crm/clientmanagers/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/crm/clientmanagers/:id",
          name: "crm-client-managers-id",
          component: () => import("@/pages/manage/crm/clientmanagers/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/crm/clients",
          name: "crm-clients",
          component: () => import("@/pages/manage/crm/clients/index.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
        {
          path: "/manage/crm/clients/:id",
          name: "crm-client-id",
          component: () => import("@/pages/manage/crm/clients/id.vue"),
          meta: {
            requiresAuth: true,
            roles: [config.roles.administrators, config.roles.siteEditors],
          },
        },
      ];
