
import {
  mdiArrowAll,
  mdiCog,
  mdiOpenInNew,
  mdiPen,
  mdiPlus,
  mdiStar,
  mdiTrashCan,
  mdiUpload,
} from "@mdi/js";
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import editor from "./editor";
import draggable from "vuedraggable";
import { ListDataSource } from "@/data/List/ListDataSource";
import { required } from "@/cms-services/consts";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
@Component({
  mixins: [editor],
  components: { ImageEditor, DataEditor, draggable },
})
export default class ResourceInlineEditor extends Vue {
  @Prop() imageSource?: ObjectDataSource;
  @Ref("imageEditor") imageEditor!: ImageEditor;
  @Ref("albumConfigurator") albumConfigurator!: DataEditor;
  @Ref("imageConfigurator") imageConfigurator!: DataEditor;
  field!: any;
  albumDataSource: ListDataSource = new ListDataSource({
    className: this.field.config.className ?? "ComponentImageAlbum",
    config: { pageSize: 1000 },
  });
  selectedAlbumId: any = null;
  $confirm: any;
  albumCache: any = null;
  albumContextMenu = false;
  x = 0;
  y = 0;

  show(e, album) {
    e.preventDefault();
    this.albumContextMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      this.albumContextMenu = true;
      this.albumCache = album;
    });
  }

  imageDataSource: ListDataSource = new ListDataSource({
    className: "ComponentImage",
    config: {
      pageSize: 1000,
      orderFieldName: "Priority",
      orderFieldDirection: "ASC",
    },
  });
  icons: any = {
    plus: mdiPlus,
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    star: mdiStar,
    open: mdiOpenInNew,
  };
  dialog = false;
  $message: any;
  dataSource!: ObjectDataSource;
  album: any = {
    componentId: null,
    petId: null,
    caption: "",
    name: "",
    description: "",
  };
  albumFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        class: "mt-5",
      },
      name: "caption",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        label: "Системное имя",
        class: "mt-5",
      },
      name: "name",
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
      },
      name: "description",
    },
  ];
  imageObjectFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Имя файла",
        class: "mt-5",
      },
      name: "name",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        label: "Системное имя",
      },
      name: "systemName",
    },
  ];
  loaded: boolean = false;

  get image(): any {
    return this.imageSource ? this.imageSource.model : this.dataSource.model;
  }

  get selectedAlbum() {
    return this.albumDataSource.items.find(
      (a: any) => a.id == this.selectedAlbumId
    );
  }

  isDefaultImage(image) {
    return (
      +image?.id === +this.dataSource.model?.[this.field?.config.fieldName]
    );
  }

  async unSelectAlbum() {
    this.imageDataSource.config.filter = JSON.stringify([
      {
        fieldName: "componentId",
        fieldValue: this.dataSource.id,
      },
      {
        fieldName: "albumIdNull",
        fieldValue: null,
      },
    ]);
    this.selectedAlbumId = null;
    await this.imageDataSource.get();
  }

  getAlbumSate(albumId) {
    return +this.selectedAlbumId === +albumId
      ? { alertColor: "success", textColor: "white--text", iconsColor: "white" }
      : { alertColor: "default", textColor: "", iconsColor: "" };
  }

  async created() {
    this.album.componentId = this.image.id;
    this.album.petId = this.image.id;
    this.albumDataSource.items = this.image.imageAlbums.sort(
      (p: any, n: any) => p.priority - n.priority
    );
    this.imageDataSource.config.filter = JSON.stringify([
      {
        fieldName: "componentId",
        fieldValue: this.image?.id,
      },
      {
        fieldName: "albumIdNull",
        fieldValue: null,
      },
    ]);
    await this.imageDataSource.get();
    this.loaded = true;
  }

  async editImageObject(image) {
    const model: any = await this.imageConfigurator.update(image);
    if (!model) return;
    Object.assign(image, model);
    await this.imageDataSource.updateField(model.id, [
      {
        fieldName: "name",
        fieldValue: model.name,
      },
      {
        fieldName: "systemName",
        fieldValue: model.systemName,
      },
    ]);
    this.$message("Успешно обновлено");
  }

  async changeImagePriority() {
    await this.imageDataSource.changePriority();
  }

  async changeAlbumPriority() {
    await this.albumDataSource.changePriority();
  }

  async selectAlbum(albumId: number | string) {
    this.selectedAlbumId = albumId;
    await this.getImagesByAlbum(albumId as number);
  }

  async toggleDefault(image) {
    await this.dataSource.updateField({
      fieldName: this.field?.config?.fieldName ?? "defaultImageId",
      fieldValue: this.isDefaultImage(image) ? null : image.id,
    });
  }

  async getImagesByAlbum(albumId: number) {
    this.imageDataSource.config.filter = JSON.stringify([
      {
        fieldName: "albumId",
        fieldValue: albumId,
      },
    ]);
    await this.imageDataSource.get();
  }

  async editImage(image: any): Promise<any> {
    const src = `/api/v1/objectimage/p${image.id}.jpg`;
    const base64 = await this.imageEditor.edit(src);
    if (!base64) return;
    image.base64 = base64?.toString().split(",")[1];
    try {
      await this.imageDataSource.update(image);
      this.$message("Изображение успешно изменено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async deleteImage(image: any) {
    const confirm: any = await this.$confirm(
      "Вы действительно хотите удалить данную картинку?"
    );
    if (!confirm) return;
    try {
      if (this.isDefaultImage(image)) {
        await this.dataSource.updateField({
          fieldName: this.field?.config?.fieldName ?? "defaultImageId",
          fieldValue: null,
        });
      }
      await this.imageDataSource.remove(image.id);
      this.$message("Изображение успешно удалено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async addImage({ file }: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async ({ target }: any) => {
      try {
        await this.imageDataSource.add({
          name: file.name,
          size: file.size,
          componentId: this.image?.id,
          base64: target.result?.toString().split(",")[1],
          albumId: this.selectedAlbumId,
        });
        this.$message("Изображение успешно добавлено");
      } catch (error) {
        this.$message(error, "error");
      }
    };
  }

  async addAlbum() {
    const model: any = await this.albumConfigurator.update(this.album);
    if (!model) return;
    try {
      await this.albumDataSource.add(model);
      this.$message("Албом успешно добавлен");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async editAlbum() {
    if (!this.albumCache) return;
    const model: any = await this.albumConfigurator.update(this.albumCache);
    if (!model) return;
    try {
      await this.albumDataSource.update(model);
      this.$message("Албом успешно изменён");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async deleteAlbum(album: any) {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данный альбом"
    );
    if (!confirm) return;
    try {
      if (+this.selectedAlbumId === +album.id) {
        this.selectedAlbumId = this.albumDataSource.items[0].id;
      }
      await this.albumDataSource.remove(album.id);
      this.$message("Албом успешно удалён");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }
}
