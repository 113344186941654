var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.dataSource.hasItems)?_c('v-data-table',{staticClass:"elevation-1 mb-3",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":_vm.dataSource.config.pageSize,"loading":_vm.loading,"server-items-length":_vm.dataSource.total},on:{"update:items-per-page":function($event){return _vm.updateOptions({ itemsPerPage: $event })},"update:page":function($event){return _vm.updateOptions({ page: $event })},"update:sort-by":function($event){return _vm.updateOptions({ sortBy: $event })},"update:sort-desc":function($event){return _vm.updateOptions({ sortDesc: $event })}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`item.${header.value}`,fn:function({ item }){return [_c('div',{key:header.value},[(header.value == 'actions')?_c('menu-loader',{ref:"menuLoaders",refInFor:true,attrs:{"col-key":header.value,"row-key":item.id}},[_vm._t(header.value,function(){return [_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.dotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.getObject(item)}}},[_c('v-list-item-title',[_vm._v(" Редактировать ")])],1),(_vm.activatable)?_c('v-list-item',{on:{"click":function($event){return _vm.changeItemState(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getItemStateName(item))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("Удалить")])],1)],1)],1)]},null,{
              item,
              changeItemState: _vm.changeItemState,
              getItemStateName: _vm.getItemStateName,
              remove: _vm.remove,
              getObject: _vm.getObject,
              executeAsyncRequest: _vm.executeAsyncRequest,
            })],2):_c('div',[_vm._t(header.value,function(){return [(header.value === 'caption')?_c('span',{staticClass:"blue--text cursor-pointer",on:{"click":function($event){return _vm.routeToPage(item.id)}}},[_vm._v(" "+_vm._s(item.caption)+" ")]):(header.value === 'instanceStateId')?_c('span',[_c('v-chip',{attrs:{"small":"","label":"","color":_vm.getInstanceStateColor(item.instanceStateId)}},[_vm._v(" "+_vm._s(_vm.getInstaceStateName(item.instanceStateId))+" ")])],1):(_vm.getField(header))?_c('inline-editor-readonly',{attrs:{"field":_vm.getField(header),"data-source":_vm.$getObjectDataSource(_vm.dataSource.className, item.id, item),"model":item}}):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])]},null,{
              item,
              changeItemState: _vm.changeItemState,
              getItemStateName: _vm.getItemStateName,
              remove: _vm.remove,
              getObject: _vm.getObject,
            })],2)],1)]}}})],null,true)}):_c('div',[_vm._v(" "+_vm._s(_vm.emptyCaption)+" ")]),_c('v-navigation-drawer',{attrs:{"stateless":"","value":_vm.drawer,"width":"60%","right":"","fixed":"","temporary":""}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDrawer}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.close))])],1),_c('v-row',[_c('v-col',[_c('h2',{staticClass:"white--text font-weight-regular ml-4"},[_vm._v("Настройки")])])],1)],1),(_vm.drawerLoaded)?_c('object-expansion-editor',{attrs:{"propertiesOverrideInfo":_vm.propertiesOverrideInfo,"propertyGroupsOverrideInfo":_vm.propertyGroupsOverrideInfo,"dataSource":_vm.objectDataSource}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }