import { getGUID } from "@/cms-services/helpers";
import Vue from "vue";

const editor = Vue.extend({
  props: {
    field: Object,
  },
  data: () => ({
    attrs: {},
    inputId: null as any,
  }),
  created() {
    this.inputId = getGUID();
  },
});

export default editor;
