import Vue from "vue";
import ContentBlockEditorEditable from "@/bootstrap/components/editor/editable.vue";
import ContentBlockEditorEditableHtml from "@/bootstrap/components/editor/editable-html.vue";

export const ContentBlock = Vue.extend({
  name: "content-block",
  components: {
    ContentBlockEditorEditable,
    ContentBlockEditorEditableHtml,
  },
  props: {
    dataSource: Object,
    block: {
      type: Object,
    },
    preview: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
    parentId: {
      type: Number,
    },
  },
  methods: {
    async updateModel() {
      await this.dataSource.updateField(this.block.id, [
        {
          fieldName: "model",
          fieldValue: this.block.model,
        },
      ]);
      (this as any).$alert("Данные успешно обновлены");
    },
  },
});
