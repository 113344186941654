export const initClientCalculationRoutes = (
    config: any,
    enabled: boolean = true,
) =>
    !enabled
        ? []
        : [
            {
                path: "/manage/client-calculations",
                name: "client-calculation",
                component: () => import("@/pages/manage/client-calculations/index.vue"),
                meta: {
                    requiresAuth: true,
                    roles: [
                        config.roles.administrators,
                        config.roles.siteEditors,
                    ],
                },
            },
            {
                path: "/manage/client-calculations/:id",
                name: "client-calculation-id",
                component: () => import("@/pages/manage/client-calculations/id.vue"),
                meta: {
                    requiresAuth: true,
                    roles: [
                        config.roles.administrators,
                        config.roles.siteEditors,
                    ],
                },
            },
        ];
