var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.rows),function(row){return _c('v-row',_vm._b({key:row.id},'v-row',row.attrs,false),_vm._l((row.cols),function(col,index){return _c('v-col',_vm._b({key:col.id},'v-col',_vm.getGrid(col.attrs),false),_vm._l((col.fields),function(field){return _c('div',{key:field.id,class:{ 'mb-5': index < col.fields.length - 1 }},[_vm._t("default",null,{"provider":{
            name: _vm.getProviderName(field),
            field,
            model: _vm.model,
            actions: _vm.actions,
            customValidators: _vm.customValidators,
            editMode: _vm.editMode,
            nested: _vm.nested,
          }})],2)}),0)}),1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }