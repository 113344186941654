
import { Vue, Component } from "vue-property-decorator";
import {
  mdiAccount,
  mdiCog,
  mdiEmail,
  mdiLogout,
  mdiFolder,
  mdiHome,
  mdiFileMultiple,
  mdiSecurity,
  mdiTableCog,
  mdiApplication,
  mdiApplicationArray,
  mdiSoundbar,
  mdiWhatsapp,
  mdiDatabaseSyncOutline,
} from "@mdi/js";
import authModule from "@/store/modules/auth";
import userModule from "@/store/modules/user";
import appConfigModule from "@/store/plugins/appConfig";
import { formatDate } from "@/cms-services/date";

@Component
export default class AppBar extends Vue {
  drawer: boolean = true;
  formatDate = formatDate;

  appConfig: any = appConfigModule;

  get profile(): any {
    return userModule.profile;
  }

  settings: any = [
    {
      title: "Мой профиль",
      icon: mdiAccount,
      id: 1,
      link: "/account",
    },
    {
      title: "Мои сообщения",
      icon: mdiEmail,
      id: 2,
      link: "/",
    },
    {
      title: "Настройки",
      icon: mdiCog,
      id: 3,
      link: "/",
    },
  ];

  icons: any = {
    logout: mdiLogout,
    home: mdiHome,
    folder: mdiFolder,
    files: mdiFileMultiple,
    security: mdiSecurity,
    tableCog: mdiTableCog,
    app: mdiApplication,
    audio: mdiSoundbar,
    message: mdiWhatsapp,
    avatar: mdiAccount,
    hub: mdiDatabaseSyncOutline,
  };

  async logout() {
    await authModule.logout();
    this.$router.push("/login/auth/");
  }
}
