
import Editor from './editor';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  mixins: [Editor],
})
export default class TitleEditor extends Vue {
  config: any;

  public get tagName(): string {
    return this.config?.tag ?? 'h2';
  }
}
