
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {
  mdiArrowLeftRight,
  mdiArrowUpDown,
  mdiCheckAll,
  mdiClose,
  mdiRotateLeft,
  mdiRotateRight,
  mdiTrashCan,
} from "@mdi/js";
const URL = window.URL || window.webkitURL;

@Component({
  components: { Cropper },
})
export default class ImageEditor extends Vue {
  @Ref("cropper") cropper!: any;

  @Prop({ default: "Редактор изображений" }) title!: string;
  @Prop({ default: "Перетащите сюда изображения или" }) placeholder!: string;
  @Prop({ default: "выберите..." }) placeholderBtnText!: string;
  @Prop({ default: 700 }) maxWidth!: any;

  icons: any = {
    close: mdiClose,
    trah: mdiTrashCan,
    checkAll: mdiCheckAll,
    rotateLeft: mdiRotateLeft,
    rotateRight: mdiRotateRight,
    arrowLeftRight: mdiArrowLeftRight,
    arrowUpDown: mdiArrowUpDown,
  };
  model:any = null;
  fields:any = null;
  actions:any = null;
  dragEnter = false;
  loading = false;
  image: any = "";
  dialog: boolean = false;
  resolve!: Function;

  public async edit(imageSrc: string | null, model?:any, fields?:any, actions?:any): Promise<any> {
    this.dialog = true;
    this.image = imageSrc;
    this.model = model;
    this.fields = fields;
    this.actions = actions;

    return new Promise<any>((resolve) => {
      this.resolve = resolve;
    });
  }

  close(model: any) {
    this.resolve(model);
    this.dialog = false;
  }

  flip(x, y) {
    this.cropper.imageTransforms.rotate % 180 !== 0
      ? this.cropper.flip(!x, !y)
      : this.cropper.flip(x, y);
  }

  finishEdit() {
    this.close(this.cropper.getResult().canvas.toDataURL());
  }

  change({ target }: any) {
    this.read(target.files);
    target.value = "";
  }

  read(files: any) {
    if (!files || files.length === 0) return;
    if (!/^image\/\w+$/.test(files[0].type)) return;
    if (!URL) return;
    this.image = URL.createObjectURL(files[0]);
  }

  async drop(e: any) {
    this.read(e.dataTransfer.files);
    this.dragEnter = false;
  }
}
