import { validate } from "@/cms-services/validators";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

@Component
export default class Editor extends Vue {
  @Prop() visualization!: any;
  @Prop() dataSource!: ObjectDataSource;
  @Prop() validations!: any;
  @Prop() field: any;
  @Prop({ default: "не определено" }) notDefinedCaption!: string;
  @Prop() customValidators!: any;
  @Watch(`dataSource.model`, { deep: true })
  modelUpdate(value: any) {
    if (this.value != this.tempValue) {
      this.value = this.tempValue;
    }
  }

  loading: boolean = false;
  value: any = null;
  editMode: boolean = false;
  errorMessages: Array<string> = [];
  successMessages: Array<string> = [];
  $message: any;



  public get readonly(): boolean {
    return this.field.readonly;
  }
  public get errorCount(): number {
    return this.errorMessages?.length ?? 0;
  }

  public get hideDetails() {
    return !(this.errorMessages.length > 0 || this.successMessages.length > 0);
  }

  public get tempValue(): string {
    if(this.field.isExtended){
      if(this.dataSource.model.extendedProperties == null){
        this.dataSource.model.extendedProperties = {
          [this.field.name]:this.field.defaultValue
        }
      }

      if(!this.dataSource.model.extendedProperties.hasOwnProperty(this.field.name)) {
        this.dataSource.model.extendedProperties[this.field.name]=this.field.defaultValue
      }


      return this.dataSource.model.extendedProperties[this.field.name]
    }



    const value = this.dataSource?.model
      ? this.dataSource.model[this.field.name]
      : null;

    if(this.field?.config?.dateFormat){
      if(moment(value, this.field?.config?.dateFormat).isValid()) return value;
      return moment(value).format(this.field?.config?.dateFormat);
    }

    return value;
  }


  public async validate(): Promise<boolean> {
    if (!this.field.validations) return true;

    const _res: any = await validate(
      this.field,
      { [this.field.name]: this.value },
      this.customValidators,
    );

    this.errorMessages = [];
    this.successMessages = [];
    return true;
  }

  created() {
    if (!this.dataSource) throw new Error("dataSource не передан");
    if (!this.field) throw new Error("field не передан");
    this.value = this.tempValue;
  }

  setEditMode() {
    this.editMode = !this.readonly;
  }

  cancelChanges() {
    this.value = this.tempValue;
    this.errorMessages = [];
    this.successMessages = [];
    this.editMode = false;
  }

  async save() {
    if (this.tempValue === this.value) {
      return this.cancelChanges();
    }


    const getExtendedFieldValue = () => {
      this.dataSource.model.extendedProperties[this.field.name] = this.value;
      return this.dataSource.model.extendedProperties;
    }

    try {
      const fieldName = this.field.isExtended ? "extendedProperties" : this.field.name
      const fieldValue = this.field.isExtended ? getExtendedFieldValue() : this.value
      await this.dataSource.updateField({fieldName, fieldValue});

      this.$emit("update", this.field.name, this.value);
      this.$message(`Поле "${this.field.caption}" успешно изменено`);
    } catch (err) {
      this.cancelChanges();
      this.$emit("error", err);
      this.$message(`Ошибка: "${err}"`, "error");

      throw err;
    } finally {
      this.editMode = false;
    }
  }
}
