
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class MenuLoader extends Vue {
  @Prop() colKey!: number;
  @Prop() rowKey!: number;
  loading: boolean = false;

  public async execute(callBack: Function) {
    this.loading = true;
    await callBack();
    this.loading = false;
  }
}
