
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component } from "vue-property-decorator";
import editor from "./editor";
@Component({
  mixins: [editor],
})
export default class InineCountrySelector extends Vue {
  loaded: boolean = false;
  field!: any;
  value: any;
  broadCastDataSource: ListDataSource = new ListDataSource({
    className: "EventBroadcast",
    config: {
      pageSize: 1000,
      filter: JSON.stringify([
        new Filter("eventId", this.field?.config?.eventId),
      ]),
    },
  });
  save: any;
  dataSource!: any;
  tempValue: any;

  async setNull() {
    this.value = null;
    await this.dataSource.updateField({
      fieldName: this.field.name,
      fieldValue: this.value,
    });

    this.value = this.tempValue;
  }

  get valueCaption() {
    const cb = (c: any) => +c.id === +this.value;
    const broadcast = this.broadCastDataSource.items.find(cb);
    console.log(this.broadCastDataSource.items, this.value, broadcast?.caption);

    return broadcast?.caption;
  }

  async created() {
    await this.broadCastDataSource.get();
    this.loaded = true;
  }
}
