import { regexList } from "./consts";
import { getValue } from "./helpers";

const required = (field: any, info: any, model: any): Promise<string> => {
  const value = getValue(model, field.name);
  return new Promise((resolve: any, reject: any) =>
    !!value ? resolve(info?.successMessage) : reject(info.errorMessage),
  );
};

const regular = (field: any, info: any, model: any): Promise<string> => {
  const value = getValue(model, field.name);
  return new Promise<string>((resolve, reject) => {
    regexList[info.value].test(value)
      ? resolve(info?.successMessage)
      : reject(info.errorMessage);
  });
};

const min = (field: any, info: any, model: any): Promise<string> => {
  const _value = getValue(model, field.name);
  return new Promise<string>((resolve, reject) => {
    const value = typeof _value === "number" ? _value : _value?.length ?? 0;
    value > info.value
      ? resolve(info?.successMessage)
      : reject(info.errorMessage);
  });
};

const max = (field: any, info: any, model: any): Promise<string> => {
  const _value = getValue(model, field.name);

  return new Promise<string>((resolve, reject) => {
    const value = typeof _value === "number" ? _value : _value?.length ?? 0;
    +value < info.value
      ? resolve(info?.successMessage)
      : reject(info.errorMessage);
  });
};

export const validators: any = { required, regular, min, max };

/**
 * Функция для валидации модели
 * @param f field
 * @param m model
 * @param c customValidations
 *
 * @returns Promise<{ errorMessages:string[], successMessages:string[] }>
 * **/
export const validate = async (f: any, m: any, c?: any): Promise<any> => {
  const errorMessages: Array<string> = [];
  const successMessages: Array<string> = [];
  for (const validation of f.validations) {
    try {
      const name = validation.validator;
      const validator = validation.custom ? c : validators;
      const successMessage = await validator[name](f, validation, m);
      if (!validation.showSuccessFeedBack) continue;
      successMessages.push(successMessage ?? "");
    } catch (errorMessage: any) {
      errorMessages.push(errorMessage);
    }
  }

  return { errorMessages, successMessages };
};

export const requiredArgument = (
  errorText: string = "Поле не может быть пустым",
) => {
  throw new Error(errorText);
};
