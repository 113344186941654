<template>
  <v-dialog
    :value="module.visible"
    :max-width="400"
    @click:outside="module.decline"
  >
    <v-card>
      <v-card-title class="headline">
        <div style="word-break: break-word">{{ module.title }}</div>
      </v-card-title>
      <v-card-text>{{ module.text }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click.prevent.stop="module.accept">
          подтвердить
        </v-btn>
        <v-btn text @click.prevent.stop="module.decline"> отмена </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import confirmModule from "@/store/plugins/confirm";
@Component
export default class Confirm extends Vue {
  module = confirmModule;
}
</script>
